import React from "react";

function ProjectDomegoKO() {
  return (
    <section className="project domego">
      <div className="container">
        <div className="projectContents">
          <small className="projectNumber">Project. 03</small>
          <h1>도매공식</h1>
          <h2>중소 도매 업체를 위한 B2B, B2C 온라인쇼핑몰 사업</h2>
          <p>
            E-Commerce에 대한 이해와 활용도가 낮아 오프라인에 집중되어 있는
            중·소 도매 마트와 납품·유통업자를 디지털 플랫폼 위로 통합하여 도매
            거래 전 과정의 연계 및 자동화, 온라인 거래 중개 서비스, 질 높은
            고객 지원, 폭넓고 다양한 거래처 확대의 기회를 제공합니다.
          </p>
        </div>
      </div>
    </section>
  );
}

export default ProjectDomegoKO;

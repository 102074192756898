import { useEffect, useState } from "react";
import RouteChangeTracker from "./RouteChangeTracker";

/* CSS */
import "./style/base.scss";
import "./style/global.scss";
import "./style/font.scss";
import "./style/main.scss";
import "./style/navBar.scss";
import "./style/main_responsive.scss";
import "./style/introduction.scss";
import "./style/introduction_responsive.scss";
import "./style/news.scss";
import "./style/news_responsive.scss";
import "./style/news_detail.scss";
import "./style/news_detail_responsive.scss";
import "./style/quill-style.scss";
import "./style/project.scss";
import "./style/project_responsive.scss";
import "./style/contact.scss";
import "./style/contact_responsive.scss";
import "./style/footer.scss";
import "./style/footer_responsive.scss";

/* 페이지 */
import AppRoutes from "./AppRoutes";
import FooterEN from "./components/FooterEN";
import NavBarEN from "./components/NavBarEN";
import NavBarKO from "./components/NavBarKO";
import FooterKO from "./components/FooterKO";

/* */
import NavBarRU from "components/NavBarRU";
import FooterRU from "components/FooterRU";

function App() {
  const [language, setLanguage] = useState("ko");
  const [headerFooterVisible, setHeaderFooterVisible] = useState(true);
  useEffect(() => {
    if (window.localStorage.getItem("lang") === "ko-KR") {
      setLanguage("ko");
    }
    if (window.localStorage.getItem("lang") === "en-US") {
      setLanguage("en");
    }
    if (window.localStorage.getItem("lang") === "ru-RU") {
      setLanguage("ru");
    }
  }, [language]);

  RouteChangeTracker();

  return (
    <>
      {headerFooterVisible &&
        {
          ko: <NavBarKO setLanguage={setLanguage} />,
          en: <NavBarEN setLanguage={setLanguage} />,
          ru: <NavBarRU setLanguage={setLanguage} />,
        }[language]}
      <AppRoutes
        language={language}
        setHeaderFooterVisible={setHeaderFooterVisible}
      />
      {headerFooterVisible &&
        {
          ko: <FooterKO />,
          en: <FooterEN />,
          ru: <FooterRU />,
        }[language]}
    </>
  );
}

export default App;

import React from "react";
import Banner from "../../components/Banner";
import ChangeDesc from "../../scripts/ChangeDesc";
import TitleMeta from "../../scripts/TitleMeta";
import * as IMGURL from "../../scripts/utils";

function MissionRU() {
  TitleMeta(`${process.env.REACT_APP_KOTITLE} 우리의 미션`);
  ChangeDesc("모세피플에게 주어진 임무와 현재 풀어가고 있는 사회 문제.");
  return (
    <div className="introductionMissionPage">
      <Banner className={"mission"} value={"Миссия"} />
      <div className="introduction_missionBackground">
        <div className="container">
          <div className="missionQuotationIcon">
            <img
              className="full-width"
              src={IMGURL.getIconURL("quotationIcon")}
              alt="quotationIcon"
            />
          </div>
          <h2>
            Мы предлагаем инновационные решения, призванные обеспечить
            удовлетворение конечных потребителей
            <br />
            <span>и принести им радость от своих покупок.</span>
          </h2>
          <p>
            В повышении счастья жизни, разумность и удовлетворенность
            потребления являются одними из ключевых факторов. Однако с каждым
            днем цены на морепродукты, мясо, сельхозпродукты и различные
            электронные товары становятся все выше, и даже в период
            восстановления экономики, цены не возвращаются на прежний уровень,
            что создает неуверенность в возможности комфортного потребления.
            Возникновение такой тенденции объясняется различными причинами,
            такими как устаревшая дистрибуционная структура, непрозрачные методы
            сделок, нехватка кадров и талантов, глобальный экономический спад,
            социальные проблемы и многое другое.
          </p>
          <p>
            Mose People обращает внимание и анализирует факторы, оказывающие
            негативное влияние на формирование цен на товары. Сегодня Mose
            People находится на передовой цифровой инновации в различных
            отраслях, таких как производство и распределение товаров сельского
            хозяйства, рыболовства и животноводства, предлагая решения,
            направленные на преодоление этих проблем.
          </p>
        </div>
      </div>
    </div>
  );
}

export default MissionRU;

import { VARS } from "../scripts/variables";

//logo URL
export const getLogoURL = (logoName, ext = "png") => {
  return VARS.PATH.CDN_URL + `assets/logo/${logoName}.${ext}`;
};

//image URL
export const getImageURL = (imageName, ext = "png") => {
  return VARS.PATH.CDN_URL + `official-site-web/images/${imageName}.${ext}`;
};

//image URL + contentNum
export const getImageContentNumURL = (imageName, contentNum, ext = "png") => {
  return (
    VARS.PATH.CDN_URL +
    `official-site-web/images/${imageName}_${contentNum}.${ext}`
  );
};

//background URL
export const getBackgroundURL = (backgroundName, ext = "png") => {
  return (
    VARS.PATH.CDN_URL + `official-site-web/background/${backgroundName}.${ext}`
  );
};

//icon URL
export const getIconURL = (iconName, ext = "png") => {
  return VARS.PATH.CDN_URL + `official-site-web/icons/${iconName}.${ext}`;
};

export const joinPath = (paths=[], sep='/') => {
  let prefix, postfix;
  prefix = postfix = '';
  if(paths[0].startsWith(sep)) {
    prefix = sep
  }
  if(paths.length > 1 && paths[paths.length-1].endsWith(sep)) {
    postfix = sep
  }
  return prefix+paths.map((path) => (
    path.trim().replace(/^[/]+/, '').replace(/[/]+$/, '')
  )).filter((x)=>x.length).join(sep)+postfix;
}

// Extract Text from HTML
export const extractText = (str_html) => {
  var span = document.createElement('span');
  span.innerHTML = str_html;
  return span.textContent || span.innerText;
};

import React from "react";

import ProjectSeaRoadEN from "./ProjectSeaRoadEN";
import ProjectIRAQEN from "./ProjectIRAQEN";
import ProjectDomegoEN from "./ProjectDomegoEN";
import TitleMeta from "../../scripts/TitleMeta";
import ChangeDesc from "../../scripts/ChangeDesc";
import { useRef } from "react";
import { useCallback } from "react";
import { useEffect } from "react";

function ProjectIndexEN() {
  TitleMeta(`${process.env.REACT_APP_ENTITLE} Project`);
  ChangeDesc(
    "International and Domestic Projects and Researches carried out by MosePeople. SeaRoad, IRAQ Export Trade, Domego."
  );

  //observer로 mouseScroll 구현중
  // const section = useRef();

  // const observerCallback = useCallback((entries) => {
  //   entries.forEach((entry) => {
  //     if (entry.isIntersecting) {
  //       console.log("change");
  //     }
  //   });
  // }, []);
  // useEffect(() => {
  //   let observer;
  //   observer = new IntersectionObserver(observerCallback, {
  //     root: null,
  //     threshold: 0.3,
  //   });
  //   section.current.forEach((el) => {
  //     observer.observe(el);
  //   });
  // }, []);

  return (
    <div id="project">
      <ProjectSeaRoadEN />
      <ProjectIRAQEN />
      <ProjectDomegoEN />
    </div>
  );
}

export default ProjectIndexEN;

import React from "react";


export const History2023EN = () => {
  return (
    <article className="historySection">
      <div className="historyYear">2023</div>
      <div className="historyMonthDesc">
        <ul>
          <li>
            <b>03</b>
            <div className="historyDescBox">
              <span className="historyMainDescWrapper">
                <span className="historyMainDesc">
                  Established a local corporation in Uzbekistan: MosePeople Uzbekistan Branch
                </span>
              </span>
            </div>
          </li>
          <li>
            <b>02</b>
            <div className="historyDescBox">
              <p>Signed MOU with East Telecom, Uzbekistan for export of Korean products to Uzbekistan</p>
              <p>Started meeting with Uzbekistan UZUM for cooperation in supplying and exporting Korean products</p>
              <p>Signed a total 3 MOUs with local business and government authority officer in Dubai, United Arab Emirates</p>
              <div className="historyMainDescImg">
                <img
                  className="full-width"
                  src="https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-backend/news-images/2023/logo-mp_et.png"
                  alt="MOU - MP & ET"
                />
                <img
                  className="full-width"
                  src="https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-backend/news-images/2023/logo-mp_uz.png"
                  alt="historyDescImg_1"
                />
              </div>
            </div>
          </li>
          <li>
            <b>01</b>
            <div className="historyDescBox">
              <p>Started exporting new Korean product category 'Cosmetics' to Iraq</p>
            </div>
          </li>
        </ul>
      </div>
    </article>
  );
};

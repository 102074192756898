import React from "react";

function ProjectIRAQEN() {
  return (
    <section className="project iraq">
      <div className="container">
        <div className="projectContents">
          <small className="projectNumber">Project. 02</small>
          <h1>IRAQ Export Trade</h1>
          <h2>The oversea market expansion project for domestic products</h2>
          <p>
            The IRAQ is a difficult market to enter because customs clearance is
            different from and more complicated than other countries for Food
            Ingredient Trade. By challenging and solving these problems,
            MosePeople is securing international sales routes and Accumulating
            opportunities and know-how for oversea market expansion.
          </p>
        </div>
      </div>
    </section>
  );
}

export default ProjectIRAQEN;

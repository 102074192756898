import React, { useEffect } from "react";
import Banner from "../../components/Banner";
import ChangeDesc from "../../scripts/ChangeDesc";
import TitleMeta from "../../scripts/TitleMeta";
import * as IMGURL from "../../scripts/utils";

function IntroductionRU() {
  useEffect(() => {
    TitleMeta(`${process.env.REACT_APP_KOTITLE} 회사 소개`);
    ChangeDesc("모세피플 회사 요약 소개와 성장 잠재력.");
  }, []);

  return (
    <div className="introductionPage">
      <Banner className={"introduction"} value={"О компании"} />
      <div className="container">
        <article className="aboutMose">
          <div className="aboutMoseImg">
            <img
              className="full-width"
              src={IMGURL.getImageURL("newsHeadImage")}
              alt="mosepeopleImage"
            />
          </div>
          <div className="aboutMoseDesc">
            <b className="aboutMoseTitle">
              Являясь членом Корейской ассоциации международной торговли (KITA),
            </b>
            <ul>
              <li>
                мы, Mose People, представляем новый путь и направление для
                совершенствования, работая с многочисленными производителями и
                поставщиками, которые испытывают трудности с выходом на рынки.
              </li>
              <li>
                Mose People помогает улучшить процесс дистрибуции за счет
                расширения внутреннего и внешнего рынков и внедрения
                ИТ-технологий, используя свой превосходный отдел продаж и
                собственную команду по исследованиям и технологиям в области
                логистики.
              </li>
              <li>
                Кроме того, основываясь на профессиональных знаниях и опыте в
                области дистрибуции морепродуктов, накопленных за годы, мы
                приняли непосредственное участие в проекте по улучшению
                внутренней структуры дистрибуции и предложили стратегии снижения
                затрат за счет повышения эффективности всего процесса
                дистрибуции от стадии эксплуатации до конечного потребителя и
                системы электронной коммерции. В знак признания ее достижений мы
                осуществили совместный проект с министерством морских дел и
                рыболовства и институтом развития морских наук и рыболовства.
              </li>
              <li>
                Исходя из этого, мы осуществляем различные проекты и соглашения
                с национальными производителями, дистрибьюторами и
                исследовательскими компаниями. Начиная с экспортной торговли
                через соглашение о продвижении в ИРАК, мы постепенно расширяемся
                по всему миру.
              </li>
            </ul>
          </div>
        </article>
        <article className="moseGrowth">
          <h1>
            <span>Потенциал роста</span> Mose People
          </h1>
          <div className="moseGrowthImg">
            <img
              className="full-width"
              src={IMGURL.getImageURL("moseGrowthPotentialImgRU")}
              alt="moseGrowthPotentialImg"
            />
          </div>
        </article>
      </div>
    </div>
  );
}

export default IntroductionRU;

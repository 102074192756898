import React from "react";
import Banner from "../../components/Banner";
import ChangeDesc from "../../scripts/ChangeDesc";
import TitleMeta from "../../scripts/TitleMeta";
import { History2023KO } from './History/History2023KO';
import * as IMGURL from "../../scripts/utils";

function HistoryKO() {
  TitleMeta(`${process.env.REACT_APP_KOTITLE} 회사 이력`);
  ChangeDesc("모세피플의 창립부터 현재까지의 이력.");
  return (
    <div className="introductionHistoryPage">
      <Banner className={"history"} value={"연혁"} />
      <div className="container">
        <div className="historyContentWrap">
          <div className="introductionContTitle">
            <h1>모세피플의 현재</h1>
            <p>그리고 지난날</p>
          </div>

          <History2023KO />

          <article className="historySection">
            <div className="historyYear">2022</div>
            <div className="historyMonthDesc">
              <ul>
                <li>
                  <div className="historyDescBox">
                    <span className="historyMainDescWrapper">
                      <span className="historyMainDesc">
                        세계로 도약하는 모세피플
                      </span>
                    </span>
                    <div className="historyMainDescImg">
                      <img
                        className="full-width"
                        src={IMGURL.getImageURL("historyDescImg_1")}
                        alt="historyDescImg_1"
                      />
                      <img
                        className="full-width"
                        src={IMGURL.getImageURL("historyDescImg_2", "jpg")}
                        alt="historyDescImg_1"
                      />
                    </div>
                  </div>
                </li>

                <li>
                  <b>10</b>
                  <div className="historyDescBox">
                    <p>한국 식자재 및 공산품 이라크 수출 MOU체결</p>
                  </div>
                </li>

                <li>
                  <b>08</b>
                  <div className="historyDescBox">
                    <p>한국무역협회 KITA 회원가입</p>
                  </div>
                </li>
                <li>
                  <b>07</b>
                  <div className="historyDescBox">
                    <p>ISO 9001, 14001 인증 발급</p>
                    <p>이라크 현지 그룹 법인 Qaswara Baghdad와 MOU 체결</p>
                    <p>이라크 해외 식자재 수출 납품</p>
                    <p>창업중심대학 초기기업 최종 협약 기업 선정</p>
                    <p>
                      해양수산과학기술진흥원(KIMST) 22년도 해양수산
                      기술사업화자금 선정
                    </p>
                  </div>
                </li>
                <li>
                  <b>06</b>
                  <div className="historyDescBox">
                    <p>제로투원파트너스 푸드테크 데모데이 선정</p>
                    <p>
                      해양수산 액셀러레이터 운영 프로그램 2개 주관기관 (MYSC,
                      CNT 테크) 서류전형 합격
                    </p>
                  </div>
                </li>
                <li>
                  <b>04</b>
                  <div className="historyDescBox">
                    <p>(주)모세피플 법인 설립</p>
                  </div>
                </li>
              </ul>
            </div>
          </article>

          <article className="historySection">
            <div className="historyYear">2021</div>
            <div className="historyMonthDesc">
              <ul>
                <li>
                  <div className="historyDescBox">
                    <span className="historyMainDescWrapper">
                      <span className="historyMainDesc">
                        새로운 출발 ! 모세피플 발자취
                      </span>
                    </span>
                  </div>
                </li>
                <li>
                  <b>12</b>
                  <div className="historyDescBox">
                    <p>
                      언론보도, 스포츠 서울 "2021 Innovation 기업 & 브랜드"
                      <br />
                      뉴스메이커 "2021 한국을 이끄는 혁신리더"
                    </p>
                  </div>
                </li>
                <li>
                  <b>11</b>
                  <div className="historyDescBox">
                    <p>언론보도, 이슈메이커 "수산업의 새로운 방향을 제시하다"</p>
                  </div>
                </li>
                <li>
                  <b>10</b>
                  <div className="historyDescBox">
                    <p>B2B 수산물 중개 프로덕트 특허 출원</p>
                  </div>
                </li>
                <li>
                  <b>07</b>
                  <div className="historyDescBox">
                    <p>
                      해양수산부 주관 "2021년 하반기 해양수산 투자유치 컨설팅
                      사업" 선정
                    </p>
                  </div>
                </li>
                <li>
                  <b>06</b>
                  <div className="historyDescBox">
                    <p>기술보증기금 1억 융자 획득</p>
                  </div>
                </li>
                <li>
                  <b>05</b>
                  <div className="historyDescBox">
                    <p>모세피플 개인사업자 설립</p>
                  </div>
                </li>
              </ul>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default HistoryKO;

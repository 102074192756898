import React from "react";

const MainFutureEN = React.forwardRef(({}, ref) => {
  return (
    <section id="mainFuture" className="section" ref={ref}>
      <div className="container">
        <div className="mainFutureWrap mainSectionTitle">
          <h1>Vision</h1>
          <p>
            We’ll lead the IT innovation of global logistics & domestic
            distribution networks so that people can
            <b> enjoy high quality products more cheap and reliably.</b>
          </p>
          <div className="futureListBoxWrap">
            <div className="futureListBox">
              <div className="futureListInnerBox">
                <p>01</p>
                <h1>Distribution</h1>
              </div>
            </div>
            <div className="futureListBox">
              <div className="futureListInnerBox">
                <p>02</p>
                <h1>IT</h1>
              </div>
            </div>
            <div className="futureListBox">
              <div className="futureListInnerBox">
                <p>03</p>
                <h1>HUMAN</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default MainFutureEN;

import React from "react";

function ProjectSeaRoadKO() {
  return (
    <section className="project seaRoad">
      <div className="container">
        <div className="projectContents">
          <small className="projectNumber">Project. 01</small>
          <h1>바닷길</h1>
          <h2>
            수산물 B2B 디지털 거래 플랫폼 및 혁신적인 스마트 유통망 구축 사업
          </h2>
          <p>
            월 수십~수백억 규모의 매출액이 예상되는 모세피플의 주요 사업 입니다.
            현 수산업계의 낙후된 거래 방식을 개선하고 기존에 없었던 스마트 전국
            유통망을 도입해, 최종 소비자에게 합리적인 가격으로 높은 품질의
            상품을 제공할 수 있도록 우리나라 수산 거래 생태계를 재편하는
            시스템을 서비스 합니다.
          </p>
        </div>
      </div>
    </section>
  );
}

export default ProjectSeaRoadKO;

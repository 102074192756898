import React from "react";
import Banner from "../../components/Banner";
import ChangeDesc from "../../scripts/ChangeDesc";
import TitleMeta from "../../scripts/TitleMeta";
import * as IMGURL from "../../scripts/utils";
import { History2023RU } from "./History/History2023RU";

function HistoryRU() {
  TitleMeta(`${process.env.REACT_APP_KOTITLE} 회사 이력`);
  ChangeDesc("모세피플의 창립부터 현재까지의 이력.");
  return (
    <div className="introductionHistoryPage">
      <Banner className={"history"} value={"История развития"} />
      <div className="container">
        <div className="historyContentWrap">
          <div className="introductionContTitle">
            <h1>Прошлое</h1>
            <p>и настоящее</p>
          </div>

          <History2023RU />

          <article className="historySection">
            <div className="historyYear">2022</div>
            <div className="historyMonthDesc">
              <ul>
                <li>
                  <div className="historyDescBox">
                    <span className="historyMainDescWrapper">
                      <span className="historyMainDesc">
                        Mose People совершает скачок на мировой рынок
                      </span>
                    </span>
                    <div className="historyMainDescImg">
                      <img
                        className="full-width"
                        src={IMGURL.getImageURL("historyDescImg_1")}
                        alt="historyDescImg_1"
                      />
                      <img
                        className="full-width"
                        src={IMGURL.getImageURL("historyDescImg_2", "jpg")}
                        alt="historyDescImg_1"
                      />
                    </div>
                  </div>
                </li>

                <li>
                  <b>10</b>
                  <div className="historyDescBox">
                    <p>
                      Было заключено соглашение о намерениях (MOU) по экспорту
                      продовольственных и промышленных товаров из Южной Кореи в
                      Ирак
                    </p>
                  </div>
                </li>

                <li>
                  <b>08</b>
                  <div className="historyDescBox">
                    <p>
                      Вступление в члены Корейской ассоциации внешней торговли
                      (KITA)
                    </p>
                  </div>
                </li>
                <li>
                  <b>07</b>
                  <div className="historyDescBox">
                    <p>Выдача сертификатов ISO 9001 и 14001</p>
                    <p>
                      Подписание соглашения о намерениях (MOU) с Qaswara Baghdad
                      в Ираке
                    </p>
                    <p>
                      Экспорт и поставка зарубежных продовольственных товаров в
                      Ирак
                    </p>
                    <p>
                      Выбор в качестве финального партнера стартапов для
                      программы "Университеты основания бизнеса"
                    </p>
                    <p>
                      Выбор Корейским институтом морской и рыбной науки и
                      технологий (KIMST) в качестве получателя финансирования на
                      осуществление технологических проектов в области морской и
                      рыбной промышленности на 2022 год
                    </p>
                  </div>
                </li>
                <li>
                  <b>06</b>
                  <div className="historyDescBox">
                    <p>
                      Выбор в качестве участника демонстрационного дня Zero to
                      One Partners в области FoodTech
                    </p>
                    <p>
                      Прохождение отборочного этапа документов в двух программах
                      акселерации в морской и рыбной промышленности,
                      организуемых двумя ведущими организациями (Морской и
                      рыболовный центр и CNT Tech)
                    </p>
                  </div>
                </li>
                <li>
                  <b>04</b>
                  <div className="historyDescBox">
                    <p>ООО "Mose People" - регистрация юридического лица</p>
                  </div>
                </li>
              </ul>
            </div>
          </article>

          <article className="historySection">
            <div className="historyYear">2021</div>
            <div className="historyMonthDesc">
              <ul>
                <li>
                  <div className="historyDescBox">
                    <span className="historyMainDescWrapper">
                      <span className="historyMainDesc">
                        Новое начало! Следы Mose People
                      </span>
                    </span>
                  </div>
                </li>
                <li>
                  <b>12</b>
                  <div className="historyDescBox">
                    <p>
                      СМИ, Seoul Sports "2021 Инновационные компании и бренды"
                      <br />
                      Новостные лидеры "2021 Ведущие инновационные лидеры Кореи"
                    </p>
                  </div>
                </li>
                <li>
                  <b>11</b>
                  <div className="historyDescBox">
                    <p>
                      Медиаобзор, Исьюмейкер "Открывая новые горизонты в сфере
                      морского промысла"
                    </p>
                  </div>
                </li>
                <li>
                  <b>10</b>
                  <div className="historyDescBox">
                    <p>
                      Подача заявки на патент на инновационный продукт для
                      посредничества в B2B торговле морепродуктами.
                    </p>
                  </div>
                </li>
                <li>
                  <b>07</b>
                  <div className="historyDescBox">
                    <p>
                      Одобрение в качестве консультанта программы "Приобретение
                      инвестиций в морской и рыболовный сектор во второй
                      половине 2021 года", организованной Министерством морской
                      и рыбной промышленности
                    </p>
                  </div>
                </li>
                <li>
                  <b>06</b>
                  <div className="historyDescBox">
                    <p>
                      Успешное получение займа в размере 100 миллионов от Фонда
                      гарантирования технологий
                    </p>
                  </div>
                </li>
                <li>
                  <b>05</b>
                  <div className="historyDescBox">
                    <p>
                      Регистрация физического лица Mose People как
                      предпринимателя
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default HistoryRU;

import React from "react";

function ProjectIRAQRU() {
  return (
    <section className="project iraq">
      <div className="container">
        <div className="projectContents">
          <small className="projectNumber">Проект 2.</small>
          <h1>Экспортная торговля с Ираком</h1>
          <h2>
            Бизнес по экспорту сельскохозяйственных продуктов в Ирак для
            расширения зарубежных рынков отечественной продукции.
          </h2>
          <p>
            Торговля сельскохозяйственными продуктами в Ирак сложна и требует
            преодоления препятствий при таможенном оформлении, что делает этот
            рынок непростым для входа. Модернизируя и решая эти сложные
            проблемы, Mose People стремится обеспечить поставку
            сельскохозяйственных продуктов в Ирак и получить опыт и возможности
            для международного развития.
          </p>
        </div>
      </div>
    </section>
  );
}

export default ProjectIRAQRU;

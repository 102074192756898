import React from "react";
import { Link } from "react-router-dom";
import * as IMGURL from "../scripts/utils";

function FooterRU() {
  return (
    <div className="footer">
      <div className="container">
        <div className="footerWrap">
          <div className="logoImg">
            <Link to={"/"}>
              <img
                className="full-width"
                src={IMGURL.getLogoURL("text_white")}
                alt="logo"
              />
            </Link>
          </div>
          <div className="footerContents">
            <div className="footerContentTop">
              <ul>
                <li>Политика конфиденциальности</li>
                <li>Условия эксплуатации</li>
                <li>
                  <Link to={"/contact"}>Контакты</Link>
                </li>
              </ul>
              <ul className="snsIconsWrap">
                <li>
                  <a href="https://www.facebook.com/mosepeople" target="blank">
                    <img
                      className="full-width"
                      src={IMGURL.getIconURL("footer_facebookIcon")}
                      alt="footer_facebookIcon"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/mosepeople/"
                    target="blank"
                  >
                    <img
                      className="full-width"
                      src={IMGURL.getIconURL("footer_instaIcon")}
                      alt="footer_kakaoIcon"
                    />
                  </a>
                </li>
                <li className="hide">
                  <a
                    href="https://map.naver.com/v5/entry/place/1719371550?c=14137148.4427624,4513862.4069140,13,0,0,0,dh&placePath=%2Fhome%3Fentry=plt"
                    target="blank"
                  >
                    <img
                      className="full-width"
                      src={IMGURL.getIconURL("footer_kakaoIcon")}
                      alt="footer_kakaoIcon"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/@mosepeople3820"
                    target="blank"
                  >
                    <img
                      className="full-width"
                      src={IMGURL.getIconURL("footer_youtubeIcon")}
                      alt="footer_youtubeIcon"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className="footerContentBottom">
              <ul>
                <li>ООО "Mose People"</li>
                <li>Представитель : Чхве Хан Соль</li>
                <li>Регистрационный номер компании : 563-81-02551</li>
              </ul>
              <ul>
                <li>Адрес компании : {process.env.REACT_APP_ADDRESS_RU}</li>
                <li>Факс : {process.env.REACT_APP_FAX}</li>
              </ul>
              <ul>
                <li>Электронная почта : {process.env.REACT_APP_EMAIL}</li>
              </ul>
            </div>
            <p>&copy; 2022-2023. MosePeople Co., Ltd. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterRU;

import React from "react";
import Banner from "../../components/Banner";
import ChangeDesc from "../../scripts/ChangeDesc";
import TitleMeta from "../../scripts/TitleMeta";
import * as IMGURL from "../../scripts/utils";
import { History2023EN } from 'pages/Introduction/History/History2023EN';

function HistoryEN() {
  TitleMeta(`${process.env.REACT_APP_ENTITLE} Company History`);
  ChangeDesc("The history of MosePeople from foundation to the present.");
  return (
    <div className="introductionHistoryPage">
      <Banner className={"history"} value={"History"} />
      <div className="container">
        <div className="historyContentWrap">
          <div className="introductionContTitle">
            <h1>The Present of MosePeople</h1>
            <p>and the Past Days</p>
          </div>

          <History2023EN />

          <article className="historySection">
            <div className="historyYear">2022</div>
            <div className="historyMonthDesc">
              <ul>
                <li>
                  <div className="historyDescBox">
                    <span className="historyMainDescWrapper">
                      <span className="historyMainDesc">
                        MosePeople leaping to the World
                      </span>
                    </span>
                    <div className="historyMainDescImg">
                      <img
                        className="full-width"
                        src={IMGURL.getImageURL("historyDescImg_1")}
                        alt="historyDescImg_1"
                      />
                      <img
                        className="full-width"
                        src={IMGURL.getImageURL("historyDescImg_2", "jpg")}
                        alt="historyDescImg_1"
                      />
                    </div>
                  </div>
                </li>

                <li>
                  <b>10</b>
                  <div className="historyDescBox">
                    <p>Signed an MOU to export Korean Food Ingredients and
                        Industrial Products to IRAQ</p>
                  </div>
                </li>

                <li>
                  <b>08</b>
                  <div className="historyDescBox">
                    <p>
                      Join as a member to Korea International Trade Association
                      (KITA)
                    </p>
                  </div>
                </li>
                <li>
                  <b>07</b>
                  <div className="historyDescBox">
                    <p>Acquired Certification ISO 9001, 14001</p>
                    <p>Signed MOU with conglomerate Qaswara Baghdad in IRAQ</p>
                    <p>Exported Food Ingredients to IRAQ</p>
                    <p>
                      Selected as a Final Agreement Company for initial start-up
                      package of Entrepreneurial University
                    </p>
                    <p>
                      Selected as a Recipient of Commercialization Fund of
                      Marine Fishery Technology from Korea Institute of Marine
                      Science & Technology Promotion (KIMST)
                    </p>
                  </div>
                </li>
                <li>
                  <b>06</b>
                  <div className="historyDescBox">
                    <p>
                      Selected as a Participant in Food Tech Demo Day hold by
                      ZeroToOnePartners
                    </p>
                    <p>
                      Passed the Document Screening for the Marine Fishery
                      Accelerator Operation Program of two leading organizations
                      MYSC, CNT Tech
                    </p>
                  </div>
                </li>
                <li>
                  <b>04</b>
                  <div className="historyDescBox">
                    <p>MosePeople Co., Ltd. was established</p>
                  </div>
                </li>
              </ul>
            </div>
          </article>

          <article className="historySection">
            <div className="historyYear">2021</div>
            <div className="historyMonthDesc">
              <ul>
                <li>
                  <div className="historyDescBox">
                    <span className="historyMainDescWrapper">
                      <span className="historyMainDesc">
                        The New Start ! Footprint of MosePeople
                      </span>
                    </span>
                  </div>
                </li>
                <li>
                  <b>12</b>
                  <div className="historyDescBox">
                    <p>
                      Press Report, Sports Seoul “2021 Innovation Enterprise &
                      Brand”
                      <br />
                      Press Report, NewsMaker “2021 Innovative Leader who lead
                      Korea”
                    </p>
                  </div>
                </li>
                <li>
                  <b>11</b>
                  <div className="historyDescBox">
                    <p>
                      Press Report, IssueMaker “MosePeople suggest new direction
                      for fishery industry”
                    </p>
                  </div>
                </li>
                <li>
                  <b>10</b>
                  <div className="historyDescBox">
                    <p>
                      Patent Application for Fishery B2B Transaction Brokerage
                      Device
                    </p>
                  </div>
                </li>
                <li>
                  <b>07</b>
                  <div className="historyDescBox">
                    <p>
                      Selected as a company for "Consulting Project for Marine
                      Fishery Investor Relation in 2nd half of 2021" by Ministry
                      of Oceans and Fisheries, Korea
                    </p>
                  </div>
                </li>
                <li>
                  <b>06</b>
                  <div className="historyDescBox">
                    <p>
                      Acquired KRW 100 million loan from Korea Technology Finance
                      Corporation
                    </p>
                  </div>
                </li>
                <li>
                  <b>05</b>
                  <div className="historyDescBox">
                    <p>MosePeople was established as a Sole Proprietorship</p>
                  </div>
                </li>
              </ul>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default HistoryEN;

import React, { useEffect } from "react";
import Banner from "../../components/Banner";
import ChangeDesc from "../../scripts/ChangeDesc";
import TitleMeta from "../../scripts/TitleMeta";
import * as IMGURL from "../../scripts/utils";

function IntroductionKO() {
  useEffect(() => {
    TitleMeta(`${process.env.REACT_APP_KOTITLE} 회사 소개`);
    ChangeDesc("모세피플 회사 요약 소개와 성장 잠재력.");
  }, []);

  return (
    <div className="introductionPage">
      <Banner className={"introduction"} value={"모세피플 소개"} />
      <div className="container">
        <article className="aboutMose">
          <div className="aboutMoseImg">
            <img
              className="full-width"
              src={IMGURL.getImageURL("newsHeadImage")}
              alt="mosepeopleImage"
            />
          </div>
          <div className="aboutMoseDesc">
            <b className="aboutMoseTitle">
              모세피플은 한국 무역협회(KITA) 회원으로써
            </b>
            <ul>
              <li>
                판로 개척에 어려움을 겪고 있는 수많은 제조·공급업자들과
                함께하면서 새로운 활로와 개선 방향을 제시합니다.
              </li>
              <li>
                모세피플은 뛰어난 영업력과 자체 운영중인 물류 연구기술팀을
                이용하여 국내·국외로의 판로 확장과 IT 기술 도입을 통한 유통
                프로세스의 개선을 돕습니다.
              </li>
              <li>
                또한 수년간 쌓아온 수산 유통에 대한 전문적인 지식과 경험을
                바탕으로 국내 유통구조 개선 사업에 직접 참여하였고, 조업
                단계부터 최종 소비자에 이르는 전 유통과정의 효율화와 E-Commerce
                시스템을 통한 비용 개선 전략을 제시하였으며 그 성과를 인정받아
                해양수산부,해양수산과학 기술진흥원과 협력사업을 진행하였습니다.
              </li>
              <li>
                이를 바탕으로 전국 규모의 제조·유통업체 및 연구업체들과 다양한
                유통 협약을 진행하고 있으며, 이라크 진출 협약을 통한 식자재 수출
                거래 달성을 시작으로 점차 세계로 뻗어 나가고 있습니다.
              </li>
            </ul>
          </div>
        </article>
        <article className="moseGrowth">
          <h1>
            <span>MOSEPEOPLE</span> Growth Potential
          </h1>
          <div className="moseGrowthImg">
            <img
              className="full-width"
              src={IMGURL.getImageURL("moseGrowthPotentialImg")}
              alt="moseGrowthPotentialImg"
            />
          </div>
        </article>
      </div>
    </div>
  );
}

export default IntroductionKO;

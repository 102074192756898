import React from "react";

import TitleMeta from "../../scripts/TitleMeta";
import ChangeDesc from "../../scripts/ChangeDesc";
import ProjectSeaRoadRU from "./ProjectSeaRoadRU";
import ProjectIRAQRU from "./ProjectIRAQRU";
import ProjectDomegoRU from "./ProjectDomegoRU";

function ProjectIndexRU() {
  TitleMeta(`${process.env.REACT_APP_KOTITLE} 프로젝트`);
  ChangeDesc(
    "모세피플이 수행하는 국제·국내의 사업과 연구과제들. 바닷길, 이라크 수출무역, 도매공식."
  );
  return (
    <div id="project">
      <ProjectSeaRoadRU />
      <ProjectIRAQRU />
      <ProjectDomegoRU />
    </div>
  );
}

export default ProjectIndexRU;

import React from "react";
import Banner from "../../components/Banner";
import ChangeDesc from "../../scripts/ChangeDesc";
import TitleMeta from "../../scripts/TitleMeta";
import * as IMGURL from "../../scripts/utils";

function WorthRU() {
  TitleMeta(`${process.env.REACT_APP_KOTITLE} 추구하는 가치 - ESG`);
  ChangeDesc(
    "모세피플이 추구하는 가치. ESG. 공감을 통한 지배구조 혁신, 천연자원을 이용하여 환경 개선, 소상공인의 경쟁력 강화 및 국가브랜딩에 기여."
  );
  return (
    <div className="introductionWorthPage">
      <Banner className={"worth"} value={"Ценности"} />
      <div className="container">
        <div className="worthContentWrap">
          <div className="introductionContTitle">
            <h1>Искренность по отношению к людям</h1>
            <p>Девиз Mose People</p>
          </div>
          <div className="worthGridWrap">
            <div className="worthGridItem governance">
              <div className="worthGridItemDesc">
                <div className="worthGridItemTitle">
                  <div className="worthIcon">
                    <img
                      className="full-width"
                      src={IMGURL.getIconURL("worthGIcon")}
                      alt="worthIcon"
                    />
                  </div>
                  <small>Governance</small>
                  <h2>Корпоративная структура</h2>
                </div>
                <h3>
                  Mose People искренне сопереживает и поддерживает друг друга.
                </h3>
                <p>
                  Мы объединились вместе для достижения лучшей и счастливой
                  жизни. Основываясь на горизонтальной культуре, прозрачной и
                  честной структуре, мы постоянно экспериментируем и внедряем
                  новые подходы, стремясь создать уникальную корпоративную
                  культуру.
                </p>
              </div>
            </div>
            <div className="worthGridItem environment">
              <div className="worthGridItemDesc">
                <div className="worthGridItemTitle">
                  <div className="worthIcon">
                    <img
                      className="full-width"
                      src={IMGURL.getIconURL("worthEIcon")}
                      alt="worthEIcon"
                    />
                  </div>
                  <small>Environment</small>
                  <h2>Окружающая среда</h2>
                </div>
                <p>
                  Мы разрабатываем и строим логистическую систему, основанную на
                  использовании природных ресурсов. Наша цель - уменьшить
                  загрязнение окружающей среды и минимизировать ресурсные
                  потери, связанные с сложными этапами распределения. Мы
                  стремимся внести свой вклад в улучшение здоровья планеты.
                </p>
              </div>
            </div>
            <div className="worthGridItem social">
              <div className="worthGridItemDesc">
                <div className="worthGridItemTitle">
                  <div className="worthIcon">
                    <img
                      className="full-width"
                      src={IMGURL.getIconURL("worthSIcon")}
                      alt="worthSIcon"
                    />
                  </div>
                  <small>Social</small>
                  <h2>Общество</h2>
                </div>
                <p>
                  Мы поддерживаем малый бизнес, предоставляя им доступ к
                  открытым данным и возможностям свободной торговли, чтобы они
                  могли справедливо конкурировать по честным и прозрачным ценам.
                  Наша работа также направлена на активизацию экономической силы
                  социально уязвимых групп и вклад в национальный бренд и
                  международное развитие нашей страны через различные отрасли
                  промышленности и международную торговлю.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorthRU;

import React from "react";

const MainAboutMOSEKO= React.forwardRef(({}, ref)=> {
  return (
    <section id="mainAbout" className="section" ref={ref}>
      <p>사람에 대한 진심에서 시작되는 모세피플의 가치</p>
    </section>
  );
})

export default MainAboutMOSEKO;

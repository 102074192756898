import React from "react";
import * as IMGURL from "../../scripts/utils";

const MainBusinessKO = React.forwardRef(({}, ref) => {
  return (
    <section id="mainBusiness" className="section" ref={ref}>
      <div className="mainBusinessImg"></div>
      <div className="container">
        <div className="mainBusinessText mainSectionTitle">
          <h1>Business</h1>
          <p>
            모세피플은 <b>B2B 도매 유통망 사업에 주력</b>하고 있으며, <br />
            우리와 뜻을 함께하는 국내·외 다양한 도매 사업체들과 협약을 체결해
            나가고 있습니다.
          </p>
          <ul>
            <li className="active">
              <div className="mainBusinessList flex">
                <div className="mainBusinessListIcon">
                  <img
                    src={IMGURL.getIconURL("mainBusinessDocumentIcon_white")}
                    alt="mainBusinessDocumentIcon_black"
                  />
                </div>
                <div className="mainBusinessListDesc">
                <p>$ 60,000,000 규모 MOU 체결: 한국 식자재 및 공산품 수출</p>
                </div>
              </div>
            </li>
            <li>
              <div className="mainBusinessList flex">
                <div className="mainBusinessListIcon">
                  <img
                    src={IMGURL.getIconURL("mainBusinessFoodIcon_black")}
                    alt="mainBusinessDocumentIcon_black"
                  />
                </div>
                <div className="mainBusinessListDesc">
                  <p>300억원 MOU 체결: E-Commerce 웹 사이트를 통한 식자재 & 공산품 판매</p>
                </div>
              </div>
            </li>
            <li>
              <div className="mainBusinessList flex">
                <div className="mainBusinessListIcon">
                  <img
                    src={IMGURL.getIconURL("mainBusinessShipIcon_black")}
                    alt="mainBusinessDocumentIcon_black"
                  />
                </div>
                <div className="mainBusinessListDesc">
                  <p>$ 400,000 협약 체결: 이라크 식자재 수출</p>
                </div>
              </div>
            </li>
            <li>
              <div className="mainBusinessList flex">
                <div className="mainBusinessListIcon">
                  <img
                    src={IMGURL.getIconURL("mainBusinessEarthIcon_black")}
                    alt="mainBusinessDocumentIcon_black"
                  />
                </div>
                <div className="mainBusinessListDesc">
                  <p>100억원 MOU 체결: B2B 수산물 유통</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
})

export default MainBusinessKO;

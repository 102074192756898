import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { extractText } from "scripts/utils";
import { API } from "scripts/variables";


export const HotNews = () => {
  const articleId = 7;  // 수동으로 지정하고 있으나, 나중엔 조회수 기반으로 top 1개를 가져올 수 있다면 정말 좋겠네~
  const [article, setArticle] = useState([]);
  const [publishedDate, setPublishedDate] = useState("");

  useEffect(() => {
    async function getArticleDetail() {
      await fetch(`${API.NEWS}/${articleId}`, {
        method: "get",
        mode: "cors",
      })
        .then((resp) => resp.json())
        .then((data) => {
          setArticle(data);
          const p_date = new Date(data.published_date);
          const [y, m , d] = [p_date.getFullYear(), p_date.getMonth() + 1, p_date.getDate()];
          setPublishedDate(`${y}. ${m}. ${d}`);
        });
    }
    getArticleDetail();
  }, [articleId]);

  return (
  <div className="hotNewsCard">
    <Link to={`/news/${articleId}`}>
      <div className="hotNewsImage">
        <img
          className="full-width"
          src={article.thumbnail_image_url}
          alt="Hot News Article"
        />
      </div>
      <div className="hotNewsText">
        <small>{publishedDate}</small>
        <h2>{article.title}</h2>
        <div className="hotNewsDescBox">
          <p>
            {extractText(article.content)}
          </p>
        </div>
      </div>
      </Link>
    </div>
  )
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Banner from "../components/Banner";
import * as IMGURL from "../scripts/utils";
import { API } from "../scripts/variables";

function NewsDetail() {

  const params = useParams();
  const articleId = params.articleId;
  const [article, setArticle] = useState([]);
  useEffect(() => {
    async function getArticleDetail() {
      await fetch(`${API.NEWS}/${articleId}`, {
        method: "get",
        mode: "cors",
      })
        .then((resp) => resp.json())
        .then((data) => {
          setArticle(data);
        });
    }
    getArticleDetail();
  }, [articleId]);
  return (
    <div className="newsDetailPage">
      <Banner className={"news-article"} value={"News"} />
      <div className="container">
        <article className="article-container">
          <div className="title">
            <h1>{article.title}</h1>
          </div>
          {/* <div className="thumbnail-wrapper">
            <img
              className="thumbnail"
              src={article.thumbnail_image_url} alt="thumbnail"
            />
          </div> */}
          {/* 아래 삽입되는 dangerously HTML은 Backend Django Server 관리자만 가능하므로 신뢰할 수 있음 */}
          <div
            className="content quill-style ql-editor"
            dangerouslySetInnerHTML={{ __html: article.content }}
          ></div>
        </article>
      </div>
    </div>
  );
}

export default NewsDetail;

import React from "react";

const MainFutureRU= React.forwardRef(({}, ref) => {
  return (
    <section id="mainFuture" className="section" ref={ref}>
      <div className="container">
        <div className="mainFutureWrap mainSectionTitle">
          <h1>Vision</h1>
          <p>
          Mose People вводит IT-инновации в мировой логистике и внутренних торговых сетях для обеспечения доступа к качественным продуктам всем людям по более низкой цене и с большей надежностью
          </p>
          <div className="futureListBoxWrap">
            <div className="futureListBox">
              <div className="futureListInnerBox">
                <p>01</p>
                <h1>Distribution</h1>
              </div>
            </div>
            <div className="futureListBox">
              <div className="futureListInnerBox">
                <p>02</p>
                <h1>IT</h1>
              </div>
            </div>
            <div className="futureListBox">
              <div className="futureListInnerBox">
                <p>03</p>
                <h1>HUMAN</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default MainFutureRU;

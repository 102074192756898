import React from "react";
import Banner from "../../components/Banner";
import ChangeDesc from "../../scripts/ChangeDesc";
import TitleMeta from "../../scripts/TitleMeta";

function FutureEN() {
  TitleMeta(`${process.env.REACT_APP_ENTITLE} Future`);
  ChangeDesc(
    "The future technology MosePeople will make into real. Digital Automation System, Computer Vision, Data Mining, AI Expert System, Automatic Driving, Drone Delivery System."
  );
  return (
    <div className="introductionFuturePage">
      <Banner className={"future"} value={"Future"} />
      <div className="container">
        <div className="futureContentWrap">
          <div className="introductionContTitle">
            <h1>Tomorrow, Better than Today</h1>
            <p>Will be made in MosePeople</p>
          </div>

          {/* futureDetail */}
          <div className="futureDetailFlexWrap">
            <div className="futureDetailItem digital">
              <div className="futureDetailTitle">
                <p>Digital Automation System</p>
              </div>
            </div>
            <div className="futureDetailItem computer">
              <div className="futureDetailTitle">
                <p>Computer Vision</p>
              </div>
            </div>
            <div className="futureDetailItem data">
              <div className="futureDetailTitle">
                <p>Data Mining</p>
              </div>
            </div>
            <div className="futureDetailItem ai">
              <div className="futureDetailTitle">
                <p>AI Expert System</p>
              </div>
            </div>
            <div className="futureDetailItem automatic">
              <div className="futureDetailTitle">
                <p>Automatic Driving</p>
              </div>
            </div>
            <div className="futureDetailItem drone">
              <div className="futureDetailTitle">
                <p>Drone Delivery System</p>
              </div>
            </div>
          </div>
          <div className="futureDescText">
            <p>
              MosePeople will continue to research to build an advanced
              logistics system through the convergence of near-future technology
              and the logistics. We will develop a more progressive Order and
              Reception Automation System, and Combine AI Technologies including
              Computer Vision, Data Mining, AI Expert System, Automatic Driving,
              Drone Delivery System to lead the next-generation logistics system
              in the upcoming future.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FutureEN;

import React from "react";
import Banner from "../../components/Banner";
import ChangeDesc from "../../scripts/ChangeDesc";
import TitleMeta from "../../scripts/TitleMeta";
import * as IMGURL from "../../scripts/utils";

function MissionEN() {
  TitleMeta(`${process.env.REACT_APP_ENTITLE} Our Mission`);
  ChangeDesc(
    "The mission given to MosePeople, and The social problem we are currently solving."
  );
  return (
    <div className="introductionMissionPage">
      <Banner className={"mission"} value={"Mission"} />
      <div className="introductionContTitle">
        <h1>The explicit goal MosePeople is heading</h1>
      </div>
      <div className="introduction_missionBackground">
        <div className="container">
          <div className="missionQuotationIcon">
            <img
              className="full-width"
              src={IMGURL.getIconURL("quotationIcon")}
              alt="quotationIcon"
            />
          </div>
          <h2>
            For Happy Consumption of End Consumer
            <br />
            <span>we suggest solution</span>
          </h2>
          <p>
            Rationality and Satisfaction of Consumption are one of the most
            important factors in Increasing Happiness in Life. However, fishery
            products, livestock products, agricultural products, and various
            electronic products are getting more and more expensive, and even
            the economy recovers, once the price has risen, it DOSE NOT return,
            making it impossible to consume comfortably. There are various
            reasons such as out of date distribution structure, opaque
            transaction process, lack of manpower and talented, global economic
            depression, and social issues.
          </p>
          <p>
            MosePeople has been paying attention to and analyzing these causes
            that adversely affect the price formation. And today, we present
            solutions to solve these causes and We are standing at forefront of
            digital innovation on domestic & international industrial
            distribution networks.
          </p>
        </div>
      </div>
    </div>
  );
}

export default MissionEN;

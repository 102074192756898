import React, { useEffect, useState } from "react";
import * as IMGURL from "../../scripts/utils";

const MainHome = React.forwardRef(({}, ref) => {
  const [mobile, setMobile] = useState(false);

  //모바일 사이즈 감지
  const handleResize = () => {
    const mobileSize = window.innerWidth;
    if (mobileSize < 479) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };
  useEffect(() => {
    handleResize();
  }, []);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section
      id="mainHome"
      className={mobile ? "mainHomeMobile" : "mainHome section"}
      ref={ref}
    >
      <div className="container">
        <div className="scrollDownIcon">
          <img
            className="full-width"
            src={IMGURL.getIconURL("scrollDownIcon")}
            alt="scrollDownIcon"
          />
        </div>
      </div>
    </section>
  );
});

export default MainHome;

import React from "react";

function ProjectIRAQKO() {
  return (
    <section className="project iraq">
      <div className="container">
        <div className="projectContents">
          <small className="projectNumber">Project. 02</small>
          <h1>IRAQ Export Trade</h1>
          <h2>국산품의 해외 수출 판로 확장을 위한 이라크 식자재 수출 사업</h2>
          <p>
            이라크 식자재 무역은 다른 국가들 보다 통관이 어렵고 복잡해서 진입이
            쉽지 않은 시장입니다. 모세피플은 이러한 어려운 문제에 도전하고
            해결함으로써, 이라크향 식자재 해외 납품 루트를 확보하고 해외 진출의
            기회와 노하우를 쌓아가고 있습니다.
          </p>
        </div>
      </div>
    </section>
  );
}

export default ProjectIRAQKO;

export const VARS = {
  PATH: {
    CDN_URL:
      !process.env.NODE_ENV || process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_CDN_URL + "/"
        : process.env.REACT_APP_CDN_URL + "/",
  },
  API_BASE_URL:
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_APISERVER_URL_DEV
      : process.env.REACT_APP_APISERVER_URL_PROD,
};

export const API = {
  NEWS: `${VARS.API_BASE_URL}/api/v1/news/articles`,  // trailing slash는 string join을 위해 빼둠
};

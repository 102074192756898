import React, { useEffect } from "react";

const MSG = {
  title: {
    KO: "지정한 페이지를 찾을 수 없습니다.",
    EN: "Page Not Found.",
  },
};

const NotFound = (props) => {
  const language = props.language ? "KO" : "EN";
  useEffect(() => {
    props.setHeaderFooterVisible(false);
  }, [props]);
  return <div>{MSG.title[language]}</div>;
};

export default NotFound;

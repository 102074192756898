import React from "react";
import Banner from "../../components/Banner";
import ChangeDesc from "../../scripts/ChangeDesc";
import TitleMeta from "../../scripts/TitleMeta";
import * as IMGURL from "../../scripts/utils";

function WorthKO() {
  TitleMeta(`${process.env.REACT_APP_KOTITLE} 추구하는 가치 - ESG`);
  ChangeDesc(
    "모세피플이 추구하는 가치. ESG. 공감을 통한 지배구조 혁신, 천연자원을 이용하여 환경 개선, 소상공인의 경쟁력 강화 및 국가브랜딩에 기여."
  );
  return (
    <div className="introductionWorthPage">
      <Banner className={"worth"} value={"가치"} />
      <div className="container">
        <div className="worthContentWrap">
          <div className="introductionContTitle">
            <h1>사람에 대한 진심</h1>
            <p>모세피플의 모토</p>
          </div>
          <div className="worthGridWrap">
            <div className="worthGridItem governance">
              <div className="worthGridItemDesc">
                <div className="worthGridItemTitle">
                  <div className="worthIcon">
                    <img
                      className="full-width"
                      src={IMGURL.getIconURL("worthGIcon")}
                      alt="worthIcon"
                    />
                  </div>
                  <small>Governance</small>
                  <h2>지배구조</h2>
                </div>
                <h3>모세피플은 서로에게 진심으로 공감하고 함께합니다.</h3>
                <p>
                  우리는 더 나은 삶, 더 행복한 삶을 위해 한 자리에 모였습니다.
                  수평적인 문화, 투명하고 깨끗한 구조를 바탕으로, 새로운 제도를
                  끊임없이 실험하고 적용하며 독자적인 기업 문화를 만들어
                  나갑니다.
                </p>
              </div>
            </div>
            <div className="worthGridItem environment">
              <div className="worthGridItemDesc">
                <div className="worthGridItemTitle">
                  <div className="worthIcon">
                    <img
                      className="full-width"
                      src={IMGURL.getIconURL("worthEIcon")}
                      alt="worthEIcon"
                    />
                  </div>
                  <small>Environment</small>
                  <h2>환경</h2>
                </div>
                <p>
                  천연 자원을 활용한 물류 시스템을 구축해 나갑니다. 복잡한 유통
                  단계에서 발생하는 대기오염, 자원의 낭비를 줄여 지구 건강
                  개선에 기여합니다.
                </p>
              </div>
            </div>
            <div className="worthGridItem social">
              <div className="worthGridItemDesc">
                <div className="worthGridItemTitle">
                  <div className="worthIcon">
                    <img
                      className="full-width"
                      src={IMGURL.getIconURL("worthSIcon")}
                      alt="worthSIcon"
                    />
                  </div>
                  <small>Social</small>
                  <h2>사회</h2>
                </div>
                <p>
                  소상공인에게 개방된 데이터, 열린거래 기회를 제공하여 건전하고
                  투명한 가격으로 경쟁을 돕습니다. <br />
                  사회 취약 계층의 경제력을 활성화 하고, 해외무역을 통해 각종
                  산업계에서 우리나라의 국가브랜딩과 국제적 경제 발전에
                  기여합니다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorthKO;

import React from "react";
import Banner from "../../components/Banner";
import ChangeDesc from "../../scripts/ChangeDesc";
import TitleMeta from "../../scripts/TitleMeta";
import * as IMGURL from "../../scripts/utils";

function MissionKO() {
  TitleMeta(`${process.env.REACT_APP_KOTITLE} 우리의 미션`);
  ChangeDesc("모세피플에게 주어진 임무와 현재 풀어가고 있는 사회 문제.");
  return (
    <div className="introductionMissionPage">
      <Banner className={"mission"} value={"미션"} />
      <div className="introduction_missionBackground">
        <div className="container">
          <div className="missionQuotationIcon">
            <img
              className="full-width"
              src={IMGURL.getIconURL("quotationIcon")}
              alt="quotationIcon"
            />
          </div>
          <h2>
            최종 소비자의 행복한 소비를 위한
            <br />
            <span>솔루션을 제시합니다.</span>
          </h2>
          <p>
            삶의 행복을 높이는데 있어 소비의 합리성과 만족도는 매우 중요한 요소
            중 하나입니다. 그러나 수산물, 축산물, 농산물, 그리고 각종 전자제품은
            점점 비싸지고 경기가 회복되어도 한번 상승한 가격은 돌아오지 않아, 맘
            편히 소비할 수 없는 시대가 되었습니다. 이렇게 날이 갈수록 비싸지는
            이유에는 낙후된 유통구조, 불투명한 거래 방식, 인력과 인재의 부족,
            전세계적인 경제침체, 사회적 이슈 등 다양한 원인들이 있습니다.
          </p>
          <p>
            모세피플은 제품들의 가격 형성에 악영향을 주는 이러한 원인들을
            주목하고 분석해 왔습니다. 그리고 오늘, 모세피플은 이러한 원인들을
            해결할 솔루션을 제시하면서 국내·국제의 공산·수산·농산·축산업 등 각종
            산업 유통망의 디지털 혁신의 첨단에 서 있습니다.
          </p>
        </div>
      </div>
    </div>
  );
}

export default MissionKO;

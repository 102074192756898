import React from "react";

const MainFutureKO= React.forwardRef(({}, ref) => {
  return (
    <section id="mainFuture" className="section" ref={ref}>
      <div className="container">
        <div className="mainFutureWrap mainSectionTitle">
          <h1>Vision</h1>
          <p>
            모세피플은 세상의 모든 사람들이 <br />
            <b>좋은 품질의 제품을 더 값싸고 안정적</b>으로 누릴 수 있도록
            <br />
            세계 물류 및 국내 유통망의 IT혁신을 주도합니다.
          </p>
          <div className="futureListBoxWrap">
            <div className="futureListBox">
              <div className="futureListInnerBox">
                <p>01</p>
                <h1>Distribution</h1>
              </div>
            </div>
            <div className="futureListBox">
              <div className="futureListInnerBox">
                <p>02</p>
                <h1>IT</h1>
              </div>
            </div>
            <div className="futureListBox">
              <div className="futureListInnerBox">
                <p>03</p>
                <h1>HUMAN</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default MainFutureKO;

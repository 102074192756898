import React from "react";

function ProjectSeaRoadRU() {
  return (
    <section className="project seaRoad">
      <div className="container">
        <div className="projectContents">
          <small className="projectNumber">Проект 1.</small>
          <h1>Морской путь</h1>
          <h2>
            Развитие прогрессивной цифровой платформы для B2B-торговли
            морепродуктами и создание инновационной сети умной дистрибуции.
          </h2>
          <p>
            Это основной бизнес-проект компании Mose People, ожидаемые с
            оборотом от нескольких десятков до нескольких сотен миллиардов вон.
            Мы улучшаем устаревшие методы торговли в текущей рыбной
            промышленности и внедряем инновационную сеть умной дистрибуции,
            которая ранее не существовала, чтобы перестроить экосистему рыбной
            торговли в Южной Корее и обеспечить конечному потребителю
            качественные товары по разумной цене.
          </p>
        </div>
      </div>
    </section>
  );
}

export default ProjectSeaRoadRU;

import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";

import IntroductionKO from "./pages/Introduction/IntroductionKO";
import MainIndexKO from "./pages/Main/MainIndexKO";
import ProjectIndexKO from "./pages/Project/ProjectIndexKO";
import ContactKO from "./pages/ContactKO";
import MissionKO from "./pages/Introduction/MissionKO";
import WorthKO from "./pages/Introduction/WorthKO";
import FutureKO from "./pages/Introduction/FutureKO";
import HistoryKO from "./pages/Introduction/HistoryKO";
import ProjectIndexEN from "./pages/Project/ProjectIndexEN";
import MainIndexEN from "./pages/Main/MainIndexEN";
import IntroductionEN from "./pages/Introduction/IntroductionEN";
import MissionEN from "./pages/Introduction/MissionEN";
import WorthEN from "./pages/Introduction/WorthEN";
import FutureEN from "./pages/Introduction/FutureEN";
import HistoryEN from "./pages/Introduction/HistoryEN";
import News from "./pages/News";
import NewsDetail from "./pages/NewsDetail";
import ContactEN from "./pages/ContactEN";
import NotFound from "./pages/NotFound";
import MainIndexRU from "pages/Main/MainIndexRU";
import IntroductionRU from "pages/Introduction/IntroductionRU";
import MissionRU from "pages/Introduction/MissionRU";
import WorthRU from "pages/Introduction/WorthRU";
import FutureRU from "pages/Introduction/FutureRU";
import HistoryRU from "pages/Introduction/HistoryRU";
import ProjectIndexRU from "pages/Project/ProjectIndexRU";
import ContactRU from "./pages/ContactRU";

const AppRoutes = (props) => {
  const language = props.language;
  return (
    <Routes>
      <Route
        path="/"
        element={
          {
            ko: <MainIndexKO language={language} />,
            en: <MainIndexEN language={language} />,
            ru: <MainIndexRU language={language} />,
          }[language]
        }
      ></Route>
      <Route path="introduction" element={<Outlet />}>
        <Route
          path=""
          element={
            {
              ko: <IntroductionKO />,
              en: <IntroductionEN />,
              ru: <IntroductionRU />,
            }[language]
          }
        ></Route>
        <Route
          path="mission"
          element={
            {
              ko: <MissionKO />,
              en: <MissionEN />,
              ru: <MissionRU />,
            }[language]
          }
        ></Route>
        <Route
          path="worth"
          element={
            {
              ko: <WorthKO />,
              en: <WorthEN />,
              ru: <WorthRU />,
            }[language]
          }
        ></Route>
        <Route
          path="future"
          element={
            {
              ko: <FutureKO />,
              en: <FutureEN />,
              ru: <FutureRU />,
            }[language]
          }
        ></Route>
        <Route
          path="history"
          element={
            {
              ko: <HistoryKO />,
              en: <HistoryEN />,
              ru: <HistoryRU />,
            }[language]
          }
        ></Route>
      </Route>
      <Route path="/news" element={<News language={language}/>}></Route>
      <Route path="/news/:articleId" element={<NewsDetail />}></Route>
      <Route
        path="/project"
        element={
          {
            ko: <ProjectIndexKO />,
            en: <ProjectIndexEN />,
            ru: <ProjectIndexRU />,
          }[language]
        }
      ></Route>
      <Route
        path="/contact"
        element={
          {
            ko: <ContactKO />,
            en: <ContactEN />,
            ru: <ContactRU />,
          }[language]
        }
      ></Route>
      <Route
        path="/*"
        element={
          <NotFound
            language={language}
            setHeaderFooterVisible={props.setHeaderFooterVisible}
          />
        }
      ></Route>
    </Routes>
  );
};

export default AppRoutes;

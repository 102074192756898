import React from "react";

const MainAboutMOSERU = React.forwardRef(({}, ref) => {
  return (
    <section id="mainAbout" className="section" ref={ref}>
      <p>
        Ценности, начинающиеся с искренности по отношению к людям, воплощены в
        миссии нашей компании <br />
        Mose People
      </p>
    </section>
  );
});

export default MainAboutMOSERU;

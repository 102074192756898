function TitleMeta(initialTitle) {
  const htmlTitle = document.querySelector("title");
  const ogTitle = document.querySelector('meta[property="og:title"]');
  const itempropName = document.querySelector('meta[itemprop="name"]');
  htmlTitle.innerText = initialTitle;
  ogTitle.content = initialTitle;
  itempropName.content = initialTitle;
}

export default TitleMeta;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API } from "../../scripts/variables";

const MainNewsKO = React.forwardRef((props, ref)=> {
  const language = props.language
  const [newsArr, setNewsArr] = useState([]);
  useEffect(() => {
    async function getNewsArr() {
      await fetch(`${API.NEWS}?recentMax=3&lang=${language}`, {
        method: "get",
        mode: "cors",
      })
        .then((resp) => resp.json())
        .then((data) => {
          setNewsArr(data);
        });
    }
    getNewsArr();
  }, [language]);

  return (
    <section id="mainNews" className="section" ref={ref}>
      <div className="mainNewsBackground">
        <div className="container">
          <div className="mainNewsContents">
            <div className="mainNewsTitle mainSectionTitle">
              <h1>News</h1>
              <p>모세피플의 오늘날</p>
            </div>
            <div className="moreButton">
              <Link to={"/news"}>More</Link>
            </div>
            <div className="mainNewsCardWrap">
              {newsArr.map((article, idx, arr) => {
                // 게시글 날짜
                let articleDate = new Date(article.published_date);
                // 게시글 Text (본문에서 text만 추출하는 과정있음)
                var tmpSpan = document.createElement("span");
                tmpSpan.innerHTML = article.content;
                var children = tmpSpan.querySelectorAll("*");
                for (var i = 0; i < children.length; i++) {
                  if (children[i].textContent) children[i].textContent += " ";
                }
                let articleContent = tmpSpan.textContent;
                return (
                  <div key={article.id} className="mainNewsCard">
                    <Link
                      to={`/news/${article.id}`}
                      className={"mainNewsCardFlexWrap"}
                    >
                      <div className="mainNewsCardImg">
                        <img
                          src={article.thumbnail_image_url}
                          alt="mainNewsThumbnail"
                        />
                      </div>
                      <div className="mainNewsCardDescWrap">
                        <p className="mainNewsDate">
                          {articleDate.getFullYear()}.{" "}
                          <span>{articleDate.getMonth() + 1}</span>
                        </p>
                        <b className="mainNewsCardTitle"></b>
                        <div className="mainNewsCardDesc">
                          <p>{articleContent}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
})

export default MainNewsKO;

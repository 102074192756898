import React from "react";

function ProjectDomegoRU() {
  return (
    <section className="project domego">
      <div className="container">
        <div className="projectContents">
          <small className="projectNumber">Проект 3.</small>
          <h1>Оптовая торговля</h1>
          <h2>
            Бизнес-модель онлайн-магазина B2B и B2C для мелких и средних оптовых
            компаний
          </h2>
          <p>
            Предоставляем возможности интеграции малых и средних оптовых рынков
            и поставщиков на цифровую платформу для автоматизации и связи всех
            этапов оптовой торговли, предоставления услуг по посредничеству в
            онлайн-торговле, высококачественной поддержки клиентов и расширения
            круга партнеров.
          </p>
        </div>
      </div>
    </section>
  );
}

export default ProjectDomegoRU;

import React, { useCallback, useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import MainScrollNav from "../../components/MainScrollNav";
import ChangeDesc from "../../scripts/ChangeDesc";
import TitleMeta from "../../scripts/TitleMeta";
import MainAboutMOSERU from "./MainAboutMOSRU";
import MainBusinessRU from "./MainBusinessRU";
import MainFutureRU from "./MainFutureRU";
import MainHome from "./MainHome";
// import MainNewsEN from "./MainNewsEN";
import MainNewsRU from "./MainNewsRU";

/* 라이브러리 */

function MainIndexRU(props) {
  const language=props.language
  TitleMeta(`${process.env.REACT_APP_KOTITLE} 한계를 넘어 세상을 연결하다`);
  ChangeDesc(
    `(주)모세피플 공식홈페이지 · CEO: 최한솔 · 주소: ${process.env.REACT_APP_ADDRESS_KO} · E-Mail: ${process.env.REACT_APP_EMAIL}  · 팩스: ${process.env.REACT_APP_FAX}`
  );

  //hook 등
  const section = useRef([]);
  const [active, setActive] = useState("");

  const observerCallback = useCallback((entries) => {
    entries.forEach((entry) => {
      // let offset;
      if (entry.isIntersecting) {
        if (entry.target === document.querySelector("#mainHome")) {
          setActive("home");
        }
        if (entry.target === document.querySelector("#mainFuture")) {
          setActive("future");
        }
        if (entry.target === document.querySelector("#mainBusiness")) {
          setActive("business");
        }
        if (entry.target === document.querySelector("#mainAbout")) {
          setActive("about");
        }
        if (entry.target === document.querySelector("#mainNews")) {
          setActive("news");
        }
        // offset = entry.target.offsetTop;
        // window.scrollTo({ top: offset, behavior: "smooth" });
        // console.log(entry.target.offsetTop);
      }
    });
  }, []);
  useEffect(() => {
    let observer;
    observer = new IntersectionObserver(observerCallback, {
      root: null,
      threshold: 0.9,
    });
    section.current.forEach((el) => {
      observer.observe(el);
    });
    //
  }, [observerCallback]);

  return (
    <>
      <MainScrollNav active={active} />
      <div id="main">
        <MainHome
          ref={(el) => {
            section.current[0] = el;
          }}
        />
        <MainFutureRU
          ref={(el) => {
            section.current[1] = el;
          }}
        />
        <MainBusinessRU
          ref={(el) => {
            section.current[2] = el;
          }}
        />
        <MainAboutMOSERU
          ref={(el) => {
            section.current[3] = el;
          }}
        />
        <MainNewsRU
          ref={(el) => {
            section.current[4] = el;
          }}
          language={language}
        />
      </div>
    </>
  );
}

export default MainIndexRU;

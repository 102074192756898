import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Banner from "../components/Banner";
import ChangeDesc from "../scripts/ChangeDesc";
import TitleMeta from "../scripts/TitleMeta";
import { HotNews } from './NewsComponents/HotNews';
import { API } from "scripts/variables";

function NewsEN(props) {
  const language = props.language
  const [newsArr, setNewsArr] = useState([]);
  const [filterNews, setFilterNews] = useState([])
  const [activeAni, setActiveAni] = useState('All')

  const categoryHandler = (e)=>{
    const text = e.target.innerText;
    setActiveAni(text)
    const filterNewsCartegory = newsArr.filter((newsList)=>{
      if (text === "All") return true;
      return newsList.category === text
    })
    setFilterNews(filterNewsCartegory);
  }

  useEffect(()=>{},[])
  useEffect(() => {
    async function getNewsArr() {
      await fetch(`${API.NEWS}/?lang=${language}`, {
        method: "get",
        mode: "cors",
      })
        .then((resp) => resp.json())
        .then((data) => {
          setNewsArr(data);
          setFilterNews(data);
        });
    }
    getNewsArr();
    setActiveAni('All')
  }, [language]);
  console.log(newsArr)
  TitleMeta(`${process.env.REACT_APP_ENTITLE} News`);
  ChangeDesc("Company News and Announcements");
  return (
    <div className="newsPage">
      <Banner className={"news"} value={"News"} />
      <div className="container">
        <div className="introductionContTitle">
          <h1>Hot News</h1>
        </div>
        <div className="newsContents">
          <HotNews />
          {/* 카테고리 버튼 */}
          <div className="newsFilterCategory">
            <ul>
              <li
                className={activeAni === "All" ? "active" : ""}
                onClick={categoryHandler}
              >
                All
              </li>
              <li
                className={activeAni === "NOTICE" ? "active" : ""}
                onClick={categoryHandler}
              >
                NOTICE
              </li>
              <li
                className={activeAni === "NEWS" ? "active" : ""}
                onClick={categoryHandler}
              >
                NEWS
              </li>
              <li
                className={activeAni === "SNS" ? "active" : ""}
                onClick={categoryHandler}
              >
                SNS
              </li>
            </ul>
          </div>
          <div className="newsCardGridWrap">
            {filterNews.length !== 0 ? filterNews.map((article, idx, arr) => {
              // 게시글 날짜
              let articleDate = new Date(article.published_date);
              return (
                <Link key={article.id} to={`/news/${article.id}`}>
                  <div className="newsCardGridItem">
                    <div className="newsCardImage">
                      <img
                        src={article.thumbnail_image_url}
                        alt="Thumbnail Url"
                      />
                    </div>
                    <h4>{article.title}</h4>
                    <p>
                      {articleDate.getFullYear()}년 {articleDate.getMonth() + 1}
                      월
                    </p>
                  </div>
                </Link>
              );
            }): <div className="emptyCategory">{{
              'ko': '해당 카테고리의 뉴스가 없습니다.',
              'en': 'No news for that category.',
              'ru': 'В этой категории нет новостей.'
            }[language]}</div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsEN;

import React from "react";


export const History2023KO = () => {
  return (
    <article className="historySection">
      <div className="historyYear">2023</div>
      <div className="historyMonthDesc">
        <ul>
          <li>
            <b>03</b>
            <div className="historyDescBox">
              <span className="historyMainDescWrapper">
                <span className="historyMainDesc">
                  우즈베키스탄 현지 법인 설립: 모세피플 우즈베키스탄 지사
                </span>
              </span>
            </div>
          </li>
          <li>
            <b>02</b>
            <div className="historyDescBox">
              <p>East Telecom(KT 우즈베키스탄 지사)과 한국 제품 우즈베키스탄 수출사업 MOU 체결</p>
              <p>우즈베키스탄 이커머스 1위 UZUM과 국산 제품 수출공급 협력 미팅 시작</p>
              <p>아랍에미리트 두바이 현지 기업 및 정부기관 담당부처와 총 3건의 MOU 체결</p>
              <div className="historyMainDescImg">
                <img
                  className="full-width"
                  src="https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-backend/news-images/2023/logo-mp_et.png"
                  alt="MOU - MP & ET"
                />
                <img
                  className="full-width"
                  src="https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-backend/news-images/2023/logo-mp_uz.png"
                  alt="historyDescImg_1"
                />
              </div>
            </div>
          </li>
          <li>
            <b>01</b>
            <div className="historyDescBox">
              <p>신규 품목 국산 화장품 이라크 수출 개시</p>
            </div>
          </li>
        </ul>
      </div>
    </article>
  );
};

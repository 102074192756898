import React from "react";

export const History2023RU = () => {
  return (
    <article className="historySection">
      <div className="historyYear">2023</div>
      <div className="historyMonthDesc">
        <ul>
          <li>
            <b>03</b>
            <div className="historyDescBox">
              <span className="historyMainDescWrapper">
                <span className="historyMainDesc">
                  Создание местного юридического лица - филиала Mose People в
                  Узбекистане
                </span>
              </span>
            </div>
          </li>
          <li>
            <b>02</b>
            <div className="historyDescBox">
              <p>
                Заключение с ООО "Ист Телеком" (филиал KT в Узбекистане)
                соглашения о намерениях (MOU) по экспорту корейских товаров в
                Узбекистан
              </p>
              <p>
                Стартовало обсуждение сотрудничество между лидирующим
                электронным коммерческим предприятием Узбекистана UZUM и
                отечественными корейскими производителями для осуществления
                экспорта и поставки местной продукции
              </p>
              <p>
                Было заключено три соглашения о намерениях (MOU) между местными
                компаниями и государственными органами Дубая, ОАЭ
              </p>
              <div className="historyMainDescImg">
                <img
                  className="full-width"
                  src="https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-backend/news-images/2023/logo-mp_et.png"
                  alt="MOU - MP & ET"
                />
                <img
                  className="full-width"
                  src="https://cdn-1-mosepeople.s3.ap-northeast-2.amazonaws.com/official-site-backend/news-images/2023/logo-mp_uz.png"
                  alt="historyDescImg_1"
                />
              </div>
            </div>
          </li>
          <li>
            <b>01</b>
            <div className="historyDescBox">
              <p>Старт экспорта отечественной косметики в Ирак</p>
            </div>
          </li>
        </ul>
      </div>
    </article>
  );
};

import React from "react";

const MainAboutMOSEEN = React.forwardRef(({}, ref) => {
  return (
    <section id="mainAbout" className="section" ref={ref}>
      <p>
        The Value of MosePeople
        <br /> beginning with Sincerity toward People
      </p>
    </section>
  );
});

export default MainAboutMOSEEN;

import React from "react";
import Banner from "../../components/Banner";
import ChangeDesc from "../../scripts/ChangeDesc";
import TitleMeta from "../../scripts/TitleMeta";

function FutureKO() {
  TitleMeta(`${process.env.REACT_APP_KOTITLE} 미래`);
  ChangeDesc(
    "모세피플이 현실로 만들어낼 미래 기술. Digital Automation System, Computer Vision, Data Mining, AI Expert System, Automatic Driving, Drone Delivery System."
  );
  return (
    <div className="introductionFuturePage">
      <Banner className={"future"} value={"미래"} />
      <div className="container">
        <div className="futureContentWrap">
          <div className="introductionContTitle">
            <h1>오늘보다, 더 나은 내일</h1>
            <p>모세피플이 만들어 갑니다</p>
          </div>

          {/* futureDetail */}
          <div className="futureDetailFlexWrap">
            <div className="futureDetailItem digital">
              <div className="futureDetailTitle">
                <p>Digital Automation System</p>
              </div>
            </div>
            <div className="futureDetailItem computer">
              <div className="futureDetailTitle">
                <p>Computer Vision</p>
              </div>
            </div>
            <div className="futureDetailItem data">
              <div className="futureDetailTitle">
                <p>Data Mining</p>
              </div>
            </div>
            <div className="futureDetailItem ai">
              <div className="futureDetailTitle">
                <p>AI Expert System</p>
              </div>
            </div>
            <div className="futureDetailItem automatic">
              <div className="futureDetailTitle">
                <p>Automatic Driving</p>
              </div>
            </div>
            <div className="futureDetailItem drone">
              <div className="futureDetailTitle">
                <p>Drone Delivery System</p>
              </div>
            </div>
          </div>
          <div className="futureDescText">
            <p>
              모세피플은 물류 산업과 근미래 기술의 융합으로 진보한 물류 시스템을
              구축하기 위해 끊임없이 연구해 나가겠습니다. 지금보다 발전된 형태의
              수·발주 자동화 시스템을 개발하고, 컴퓨터 비전, 데이터 마이닝, AI
              전문가 시스템, 자율주행, 드론 배송 시스템 등 인공지능 분야의
              기술을 접목하여 다가오는 미래의 차세대 물류 시스템을 선도해 나갈
              것입니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FutureKO;

import React from "react";

function ProjectDomegoEN() {
  return (
    <section className="project domego">
      <div className="container">
        <div className="projectContents">
          <small className="projectNumber">Project. 03</small>
          <h1>DomeGo</h1>
          <h2>
            B2B & B2C E-Commerce shopping mall for small and medium wholesalers
          </h2>
          <p>
            By integrating small and medium size wholesale marts, suppliers, and
            distributors which are concentrated yet offline market due to low
            understanding and utilization about E-Commerce, on a digital
            platform, We provides connection and automation of the entire
            wholesale transaction process, online transaction brokerage service,
            high-quality customer support, and opportunities to expand a
            variety of business partners.
          </p>
        </div>
      </div>
    </section>
  );
}

export default ProjectDomegoEN;

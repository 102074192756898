import React from "react";
import * as IMGURL from "../../scripts/utils";

const MainBusinessRU = React.forwardRef(({}, ref) => {
  return (
    <section id="mainBusiness" className="section" ref={ref}>
      <div className="mainBusinessImg"></div>
      <div className="container">
        <div className="mainBusinessText mainSectionTitle">
          <h1>Business</h1>
          <p>
            Mose People активно занимается оптовыми дистрибьюторскими сетями B2B
            и заключает соглашения с различными отечественными и зарубежными
            оптовыми предприятиями, разделяющими наши цели и намерения
          </p>
          <ul>
            <li className="active">
              <div className="mainBusinessList flex">
                <div className="mainBusinessListIcon">
                  <img
                    src={IMGURL.getIconURL("mainBusinessDocumentIcon_white")}
                    alt="mainBusinessDocumentIcon_black"
                  />
                </div>
                <div className="mainBusinessListDesc">
                  <p>
                    Заключение соглашения о намерениях (MOU) на сумму 60 млн.
                    долларов: экспорт продовольственных и промышленных товаров
                    из Южной Кореи
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="mainBusinessList flex">
                <div className="mainBusinessListIcon">
                  <img
                    src={IMGURL.getIconURL("mainBusinessFoodIcon_black")}
                    alt="mainBusinessDocumentIcon_black"
                  />
                </div>
                <div className="mainBusinessListDesc">
                  <p>
                    Заключение соглашения о намерениях (MOU) на сумму 300 млрд.
                    вон: продажа продовольственных и промышленных товаров через
                    интернет-сайт электронной коммерции
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="mainBusinessList flex">
                <div className="mainBusinessListIcon">
                  <img
                    src={IMGURL.getIconURL("mainBusinessShipIcon_black")}
                    alt="mainBusinessDocumentIcon_black"
                  />
                </div>
                <div className="mainBusinessListDesc">
                  <p>
                    Заключение соглашения на сумму 400 000 долларов: экспорт
                    продовольственных товаров в Ирак
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="mainBusinessList flex">
                <div className="mainBusinessListIcon">
                  <img
                    src={IMGURL.getIconURL("mainBusinessEarthIcon_black")}
                    alt="mainBusinessDocumentIcon_black"
                  />
                </div>
                <div className="mainBusinessListDesc">
                  <p>
                    Заключение соглашения на сумму 100 млрд. вон: оптовая
                    торговля морепродуктами B2B
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
});

export default MainBusinessRU;

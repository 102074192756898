import React, { useEffect, useState } from "react";
import * as IMGURL from "../scripts/utils";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { HiOutlineExternalLink } from "react-icons/hi";
import { MdLanguage, MdArrowDropDown } from "react-icons/md";

function NavBarEN({ setLanguage }) {
  const [hover, setHover] = useState("leave");
  const [navTheme, setNavTheme] = useState("");
  const [scrollY, setScrollY] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [aboutActive, setAboutActive] = useState(false);
  const [languageActive, setLanguageActive] = useState(false);
  const { pathname } = useLocation();
  const handleScroll = () => {
    setScrollY(window.pageYOffset);
  };
  const onClickHamburger = () => {
    setIsOpen(true);
  };
  const onClickCloseButton = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    if (scrollY === 0) {
      setNavTheme(true);
    } else if (scrollY !== 0) {
      setNavTheme(false);
    }
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollY]);

  useEffect(()=>{
    if(window.scrollY !== 0) {
      setNavTheme(false)
    }
  },[])
  //언어변경 버튼
  const handleLang = (e) => {
    const lang = e.target.dataset.lang;
    if (lang === "ko") {
      window.localStorage.setItem("lang", "ko-KR");
      setLanguage('ko');
    }
    if (lang === "en") {
      window.localStorage.setItem("lang", "en-US");
      setLanguage('en');
    }
    if (lang === "ru") {
      window.localStorage.setItem("lang", "ru-RU");
      setLanguage('ru');
    }
  };

  //hoverActive 함수
  const handleMouseOver = () => {
    setAboutActive(true);
  };
  const handleMouseOut = () => {
    setAboutActive(false);
  };

  //hoverActive 함수
  const handleMouseOverLang = () => {
    setLanguageActive(true);
  };
  const handleMouseOutLang = () => {
    setLanguageActive(false);
  };

  return (
    <>
      <div
        className={navTheme & (hover === "leave") ? "nav" : "nav activeNav"}
        onMouseEnter={() => setHover("hover")}
        onMouseLeave={() => setHover("leave")}
      >
        <div className="container">
          <div className="navBar">
            <div className="logoImg">
              <Link to={"/"}>
                <img
                  className="full-width"
                  src={
                    navTheme & (hover === "leave")
                      ? IMGURL.getLogoURL("text_white")
                      : IMGURL.getLogoURL("text_black")
                  }
                  alt="logo"
                />
              </Link>
            </div>
            <ul className="navBarLink">
              <li
                className="navBarLinkIntroduction aboutUsClass"
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              >
                <Link to={"/introduction"} className="dropdownFlex">
                  <p>About Us</p>
                  <MdArrowDropDown className="dropdownIcon pointer" />
                </Link>
                <div
                  className={aboutActive ? "introductionCategory" : "hideClass"}
                >
                  <ul>
                    <li>
                      <Link to={"/introduction"}>MosePeople</Link>
                    </li>
                    <li>
                      <Link to={"/introduction/history"}>History</Link>
                    </li>
                    <li>
                      <Link to={"/introduction/mission"}>Mission</Link>
                    </li>
                    <li>
                      <Link to={"/introduction/worth"}>Values</Link>
                    </li>
                    <li>
                      <Link to={"/introduction/future"}>Future</Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <Link to={"/news"}>News</Link>
              </li>
              <li>
                <Link to={"/project"}>Project</Link>
              </li>
              <li>
                <Link to={"/contact"}>Contact</Link>
              </li>
              <li>
                <a
                  href="https://career.mosepeople.com/"
                  target="blank"
                  className="buttonTag"
                >
                  <div className="recruitButton">
                    <p>Career</p>
                    <HiOutlineExternalLink className="careerIcon" />
                  </div>
                </a>
              </li>
              <li
                className="navBarLinkIntroduction"
                onMouseOver={handleMouseOverLang}
                onMouseOut={handleMouseOutLang}
              >
                <Link>
                  <MdLanguage className="languageIcon" />
                  <MdArrowDropDown className="dropdownIcon pointer" />
                </Link>
                <div
                  className={
                    languageActive
                      ? "introductionCategory language"
                      : "hideClass"
                  }
                >
                  <ul>
                    <li className="pointer" data-lang="ko" onClick={handleLang}>
                      한국어
                    </li>
                    <li className="pointer" data-lang="en" onClick={handleLang}>
                      English
                    </li>
                    <li className="pointer" data-lang="ru" onClick={handleLang}>
                      Русский язык
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <div className="hamburgerMenu pointer" onClick={onClickHamburger}>
              <AiOutlineMenu />
            </div>
          </div>
        </div>
      </div>
      <div className={isOpen ? "blackBackground" : "blackBackground hide"}>
        <div className={isOpen ? "sideBar" : "sideBar hide"}>
          <div className="closeButton pointer" onClick={onClickCloseButton}>
            <AiOutlineClose />
          </div>
          <div className="sideBarContents">
            <div className="sideBarGreeting">
              Hello <br />
              We Are MOSEPEOPLE
            </div>
            <div className="sideBarListWrap">
              <ul>
                <li>
                  <Link to="/introduction">About MOSE</Link>
                  <ul className="introductionSubMenu">
                    <li>
                      <Link to="/introduction/history">HISTORY</Link>
                    </li>
                    <li>
                      <Link to="/introduction/mission">MISSION</Link>
                    </li>
                    <li>
                      <Link to="/introduction/worth">VALUES</Link>
                    </li>
                    <li>
                      <Link to="/introduction/future">FUTURE</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/news">NEWS</Link>
                </li>
                <li>
                  <Link to="/project">PROJECT</Link>
                </li>
                <li>
                  <Link to="/contact">CONTACT</Link>
                </li>
                <li>
                  <a href="https://career.mosepeople.com/" target="blank">
                    CARRER
                  </a>
                </li>
              </ul>
            </div>
            <ul className="languageCheck">
              <li className="pointer" data-lang="ko" onClick={handleLang}>
                한국어
              </li>
              <li className="dot">&middot;</li>
              <li className="pointer" data-lang="en" onClick={handleLang}>
                English
              </li>
              <li className="dot">&middot;</li>
              <li className="pointer" data-lang="ru" onClick={handleLang}>
                Русский язык
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default NavBarEN;

import React from "react";
import Banner from "../../components/Banner";
import ChangeDesc from "../../scripts/ChangeDesc";
import TitleMeta from "../../scripts/TitleMeta";

function FutureRU() {
  TitleMeta(`${process.env.REACT_APP_KOTITLE} 미래`);
  ChangeDesc(
    "모세피플이 현실로 만들어낼 미래 기술. Digital Automation System, Computer Vision, Data Mining, AI Expert System, Automatic Driving, Drone Delivery System."
  );
  return (
    <div className="introductionFuturePage">
      <Banner className={"future"} value={"Будущее"} />
      <div className="container">
        <div className="futureContentWrap">
          <div className="introductionContTitle">
            <h1>Мы создаем лучшее завтра,</h1>
            <p>чем сегодня, Mose People</p>
          </div>

          {/* futureDetail */}
          <div className="futureDetailFlexWrap">
            <div className="futureDetailItem digital">
              <div className="futureDetailTitle">
                <p>Digital Automation System</p>
              </div>
            </div>
            <div className="futureDetailItem computer">
              <div className="futureDetailTitle">
                <p>Computer Vision</p>
              </div>
            </div>
            <div className="futureDetailItem data">
              <div className="futureDetailTitle">
                <p>Data Mining</p>
              </div>
            </div>
            <div className="futureDetailItem ai">
              <div className="futureDetailTitle">
                <p>AI Expert System</p>
              </div>
            </div>
            <div className="futureDetailItem automatic">
              <div className="futureDetailTitle">
                <p>Automatic Driving</p>
              </div>
            </div>
            <div className="futureDetailItem drone">
              <div className="futureDetailTitle">
                <p>Drone Delivery System</p>
              </div>
            </div>
          </div>
          <div className="futureDescText">
            <p>
              Mose People постоянно исследует слияние логистической индустрии с
              передовыми технологиями будущего, чтобы создать современную
              логистическую систему. Мы разрабатываем автоматизированную систему
              заказов и поставок, превосходящую текущие возможности. Также
              интегрируем технологии компьютерного зрения, добычи данных,
              экспертных систем искусственного интеллекта, автономного вождения,
              системы доставки дронов и другие разработки в области
              искусственного интеллекта, чтобы быть во главе следующего
              поколения логистических систем будущего.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FutureRU;

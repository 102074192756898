import React from "react";

function Banner(props) {
  return (
    <div className={`banner ${props.className}`}>
      <p className="bannerTitle">{props.value}</p>
    </div>
  );
}

export default Banner;

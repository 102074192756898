function ChangeDesc(initialDesc) {
  const htmlDesc = document.querySelector('meta[name="description"]');
  const ogDesc = document.querySelector('meta[property="og:description"]');
  const twitterDesc = document.querySelector(
    'meta[name="twitter:description"]'
  );
  const itempropDesc = document.querySelector('meta[itemprop="description"]');

  htmlDesc.content = initialDesc;
  ogDesc.content = initialDesc;
  twitterDesc.content = initialDesc;
  itempropDesc.content = initialDesc;
}

export default ChangeDesc;

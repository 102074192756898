import React, { useState, useEffect, useCallback } from "react";

function MainScrollNav({ active }) {
  const [scrollY, setScrollY] = useState(0);
  const [activeNav, setActiveNav] = useState("home");
  const [navHide, setNavHide] = useState(false);

  //scrollEvent
  const handleScroll = () => {
    setScrollY(window.pageYOffset);
    if (
      (scrollY >= document.getElementById("mainHome").offsetTop) &
      (scrollY < document.getElementById("mainFuture").offsetTop)
    ) {
      setActiveNav("home");
    }
    // if (window.pageYOffset <= document.getElementById("mainFuture").offsetTop) {
    //   setActiveNav("future");
    // }
    // if (
    //   window.pageYOffset <= document.getElementById("mainBusiness").offsetTop
    // ) {
    //   setActiveNav("business");
    // }
    // if (window.pageYOffset <= document.getElementById("mainAbout").offsetTop) {
    //   setActiveNav("about");
    // }
    // if (window.pageYOffset === document.getElementById("mainNews").offsetTop) {
    //   setActiveNav("news");
    // }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollY]);
  //sectionNav click function
  const navClickHandler = useCallback((e) => {
    const sectionName = e.currentTarget.dataset.section;
    setActiveNav(sectionName);
    let offset;
    switch (sectionName) {
      case "home":
        offset = document.getElementById("mainHome").offsetTop;
        break;
      case "future":
        offset = document.getElementById("mainFuture").offsetTop;
        break;
      case "business":
        offset = document.getElementById("mainBusiness").offsetTop;
        break;
      case "about":
        offset = document.getElementById("mainAbout").offsetTop;
        break;
      case "news":
        offset = document.getElementById("mainNews").offsetTop;
        break;
      default:
        break;
    }
    window.scrollTo({ top: offset, behavior: "smooth" });
  }, []);

  return (
    <div id="mainScrollNav">
      <ul>
        <li
          className={active === "home" ? "active" : ""}
          data-section={"home"}
          onClick={navClickHandler}
        >
          <div className="circleBox">
            <span></span>
            <span></span>
          </div>
          <div className="scrollSectionName">HOME</div>
        </li>
        <li
          className={active === "future" ? "active" : ""}
          data-section={"future"}
          onClick={navClickHandler}
        >
          <div className="circleBox">
            <span></span>
            <span></span>
          </div>
          <div className="scrollSectionName">FUTURE</div>
        </li>
        <li
          className={active === "business" ? "active" : ""}
          data-section={"business"}
          onClick={navClickHandler}
        >
          <div className="circleBox">
            <span></span>
            <span></span>
          </div>
          <div className="scrollSectionName">BUSINESS</div>
        </li>
        <li
          className={active === "about" ? "active" : ""}
          data-section={"about"}
          onClick={navClickHandler}
        >
          <div className="circleBox">
            <span></span>
            <span></span>
          </div>
          <div className="scrollSectionName">ABOUT MOSE</div>
        </li>
        <li
          className={active === "news" ? "active" : ""}
          data-section={"news"}
          onClick={navClickHandler}
        >
          <div className="circleBox">
            <span></span>
            <span></span>
          </div>
          <div className="scrollSectionName">NEWS</div>
        </li>
      </ul>
    </div>
  );
}

export default MainScrollNav;

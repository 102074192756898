import React from "react";

function ProjectSeaRoadEN() {
  return (
    <section className="project seaRoad">
      <div className="container">
        <div className="projectContents">
          <small className="projectNumber">Project. 01</small>
          <h1>SeaRoad</h1>
          <h2>
            B2B Digital Trading Platform for Marine Product and Innovative Smart
            Distribution Network
          </h2>
          <p>
            The main business of MosePeople, which is expected sales of tens of
            billion KRW per month. We serve a system that reorganizes the
            fishery trade ecosystem in Korea to provide high quality products at
            reasonable price to end consumers by improving out of date trade
            process of the current fishery industry and introducing a nationwide
            smart distribution network did not exist before.
          </p>
        </div>
      </div>
    </section>
  );
}

export default ProjectSeaRoadEN;

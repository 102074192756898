import React from "react";
import Banner from "../../components/Banner";
import ChangeDesc from "../../scripts/ChangeDesc";
import TitleMeta from "../../scripts/TitleMeta";
import * as IMGURL from "../../scripts/utils";

function WorthEN() {
  TitleMeta(`${process.env.REACT_APP_ENTITLE} Pursuing Values - ESG`);
  ChangeDesc(
    "The values MosePeople pursues. ESG. Governance innovation through empathy, Environmental Improvement using natural resources, and Social contribution through strengthening the competitiveness of small businesses and national branding."
  );
  return (
    <div className="introductionWorthPage">
      <Banner className={"worth"} value={"Values"} />
      <div className="container">
        <div className="worthContentWrap">
          <div className="introductionContTitle">
            <h1>Sincerity toward People</h1>
            <p>Motto of MosePeople</p>
          </div>
          <div className="worthGridWrap">
            <div className="worthGridItem governance">
              <div className="worthGridItemDesc">
                <div className="worthGridItemTitle">
                  <div className="worthIcon">
                    <img
                      className="full-width"
                      src={IMGURL.getIconURL("worthGIcon")}
                      alt="worthIcon"
                    />
                  </div>
                  <small>Governance</small>
                  <h2>
                    MosePeople sincerely sympathize with each other and be
                    together
                  </h2>
                </div>
                <p>
                  We come here together for a better and happier life. Based on
                  horizontal culture, transparent and clean structure, We
                  constantly experiment and apply new systems to create unique
                  corporate culture.
                </p>
              </div>
            </div>
            <div className="worthGridItem environment">
              <div className="worthGridItemDesc">
                <div className="worthGridItemTitle">
                  <div className="worthIcon">
                    <img
                      className="full-width"
                      src={IMGURL.getIconURL("worthEIcon")}
                      alt="worthEIcon"
                    />
                  </div>
                  <small>Environment</small>
                </div>
                <p>
                  We are going to build a logistics system utilizing natural
                  resources. By reducing air pollution and waste of resources
                  during the complex distribution process, contributes to
                  restoring the health of the earth.
                </p>
              </div>
            </div>
            <div className="worthGridItem social">
              <div className="worthGridItemDesc">
                <div className="worthGridItemTitle">
                  <div className="worthIcon">
                    <img
                      className="full-width"
                      src={IMGURL.getIconURL("worthSIcon")}
                      alt="worthSIcon"
                    />
                  </div>
                  <small>Social</small>
                </div>
                <p>
                  We provide open data and open transaction opportunities to
                  small businesses to help sound price competition. Activate the
                  economic power of the vulnerable members of society. <br />
                  Contributes to Korea national branding and international
                  economic development in various industries.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorthEN;

import React from "react";
import * as IMGURL from "../../scripts/utils";

const MainBusinessEN = React.forwardRef(({}, ref) => {
  return (
    <section id="mainBusiness" className="section" ref={ref}>
      <div className="mainBusinessImg"></div>
      <div className="container">
        <div className="mainBusinessText mainSectionTitle">
          <h1>Business</h1>
          <p>
            We’re <b>focusing on B2B wholesale distribution business,</b> and
            concluding agreements with various domestic & international
            companies that share with our goal.
          </p>
          <ul>
            <li className="active">
              <div className="mainBusinessList flex">
                <div className="mainBusinessListIcon">
                  <img
                    src={IMGURL.getIconURL("mainBusinessDocumentIcon_white")}
                    alt="mainBusinessDocumentIcon_black"
                  />
                </div>
                <div className="mainBusinessListDesc">
                  <p>
                    $ 60 million worth MOU of Export Food Ingredients &
                    Industrial Product
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="mainBusinessList flex">
                <div className="mainBusinessListIcon">
                  <img
                    src={IMGURL.getIconURL("mainBusinessFoodIcon_black")}
                    alt="mainBusinessDocumentIcon_black"
                  />
                </div>
                <div className="mainBusinessListDesc">
                  <p>
                    $ 23 million MOU for Sales of Food Ingredients & Industrial
                    Product using E-Commerce Web Service
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="mainBusinessList flex">
                <div className="mainBusinessListIcon">
                  <img
                    src={IMGURL.getIconURL("mainBusinessShipIcon_black")}
                    alt="mainBusinessDocumentIcon_black"
                  />
                </div>
                <div className="mainBusinessListDesc">
                  <p>$ 400,000 MOU of Exporting Food Material</p>
                </div>
              </div>
            </li>
            <li>
              <div className="mainBusinessList flex">
                <div className="mainBusinessListIcon">
                  <img
                    src={IMGURL.getIconURL("mainBusinessEarthIcon_black")}
                    alt="mainBusinessDocumentIcon_black"
                  />
                </div>
                <div className="mainBusinessListDesc">
                  <p>$ 7.6 million MOU of B2B Marine Product Distribution</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
});

export default MainBusinessEN;

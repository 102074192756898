import React from "react";
import Banner from "../../components/Banner";
import ChangeDesc from "../../scripts/ChangeDesc";
import TitleMeta from "../../scripts/TitleMeta";
import * as IMGURL from "../../scripts/utils";

function IntroductionEN() {
  TitleMeta(`${process.env.REACT_APP_ENTITLE} About Us`);
  ChangeDesc("MosePeople Co., Ltd. Summary Introduction and Growth Potential.");
  return (
    <div className="introductionPage">
      <Banner className={"introduction"} value={"MosePeople"} />
      <div className="container">
        <article className="aboutMose">
          <div className="aboutMoseImg">
            <img
              className="full-width"
              src={IMGURL.getImageURL("newsHeadImage")}
              alt="mosepeopleImage"
            />
          </div>
          <div className="aboutMoseDesc">
            <b className="aboutMoseTitle">
              As a Member of Korea International Trade Association (KITA),
            </b>
            <ul>
              <li>
                MosePeople finds a way out to open up markets, and suggests
                improvement direction to manufacturers·suppliers who have
                difficulties.
              </li>
              <li>
                MosePeople helps to ameliorate the distribution process, through
                the introduction of IT technology and the enlargement of
                international and domestic market, based on our powerful sales
                force and own logistics R&D team.
              </li>
              <li>
                We have directly participated in the government’s seafood
                distribution structure improvement project, based on our
                professional knowledge and experience in the marine food
                business over the years. Also, we’ve proposed cost reduction
                strategies through efficienating the entire distribution process
                from the fishing stage to the final consumer and the E-Commerce
                system. In recognition of its achievements, we performed the
                project under the Ministry of Oceans and Fisheries and the Korea
                Institute of Marine Science & Technology Promotion.
              </li>
              <li>
                On that basis, we are carrying out various projects and
                agreements with national manufacturers, distributors and
                research companies. Starting with the export trade through the
                agreement to advance into IRAQ, we are gradually branching out
                to the world.
              </li>
            </ul>
          </div>
        </article>
        <article className="moseGrowth">
          <h1>
            <span>MOSEPEOPLE</span> Growth Potential
          </h1>
          <div className="moseGrowthImg">
            <img
              className="full-width"
              src={IMGURL.getImageURL("moseGrowthPotentialImgEN")}
              alt="moseGrowthPotentialImg"
            />
          </div>
        </article>
      </div>
    </div>
  );
}

export default IntroductionEN;

import React, { useState, useEffect } from "react";
import Banner from "../components/Banner";
import * as IMGURL from "../scripts/utils";
import { GoLocation } from "react-icons/go";
import { GrMail } from "react-icons/gr";
import { FaFax } from "react-icons/fa";
import TitleMeta from "../scripts/TitleMeta";
import ChangeDesc from "../scripts/ChangeDesc";

function ContactKO() {
  TitleMeta(`${process.env.REACT_APP_KOTITLE} 문의`);
  ChangeDesc(
    "모세피플과 연락할 수 있는 수단. 회사주소, 이메일, 팩스, 지도와 회사사진."
  );
  return (
    <div className="contactPage">
      <Banner className={"contact"} value={"문의"} />
      <div className="container">
        <div className="introductionContTitle">
          <h1>오시는 길</h1>
        </div>
        <div className="contactContents">
          <div className="contactImageGridWrap">
            <img
              src={IMGURL.getImageURL("contact_companyImage")}
              alt="contact_companyImage"
              className="contact_companyImage full-width"
            />

            <div className="relativeImg">
              <img
                className="full-width"
                src={IMGURL.getBackgroundURL("companyAddressBackground")}
                alt="companyAddressBackground"
              />
              <div className="companyAddress">
                <ul>
                  <li>
                    <div>
                      <GoLocation />
                    </div>
                    <div>
                      <p>본사주소</p>
                      <span>{process.env.REACT_APP_ADDRESS_KO}</span>
                    </div>
                  </li>
                  <li className="flex">
                    <div>
                      <GrMail />
                    </div>
                    <div>
                      <p>E-Mail</p>
                      <span>{process.env.REACT_APP_EMAIL}</span>
                    </div>
                  </li>
                  <li className="flex">
                    <div>
                      <FaFax />
                    </div>
                    <div>
                      <p>Fax</p>
                      <span>{process.env.REACT_APP_FAX}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1118.0128085059926!2d126.82099617449477!3d37.561481668159466!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca3dde9821263%3A0xfa9f5efb7c6cf380!2zKOyjvCnrqqjshLjtlLztlIwsIE1vc2VQZW9wbGUgQ28uLCBMdGQu!5e0!3m2!1sko!2skr!4v1684141745797!5m2!1sko!2skr"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <a
            href="https://my.matterport.com/show/?m=atjrQSt5RK5"
            target="_blank"
            rel="noreferrer"
          >
            <div className="virtualtourButton">오피스 3D 가상투어 ▶</div>
          </a>
          <div className="companyFailityGridWrap">
            <img
              className="full-width"
              src={IMGURL.getImageContentNumURL("companyFacility", "01")}
              alt="companyFacilityImage"
            />
            <img
              className="full-width"
              src={IMGURL.getImageContentNumURL("companyFacility", "02")}
              alt="companyFacilityImage"
            />
            <img
              className="full-width"
              src={IMGURL.getImageContentNumURL("companyFacility", "03")}
              alt="companyFacilityImage"
            />
            <img
              className="full-width"
              src={IMGURL.getImageContentNumURL("companyFacility", "04")}
              alt="companyFacilityImage"
            />
            <img
              className="full-width"
              src={IMGURL.getImageContentNumURL("companyFacility", "05")}
              alt="companyFacilityImage"
            />
            <img
              className="full-width"
              src={IMGURL.getImageContentNumURL("companyFacility", "06")}
              alt="companyFacilityImage"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactKO;
